<template>
  <HeadlessListbox v-slot="{ open }" :modelValue="locale" @update:modelValue="(value: string) => switchLocale(value)">
    <div class="relative">
      <HeadlessListboxButton
        class="relative rounded-lg py-2 px-4 text-left text-ng600 hover:text-ng800 focus:outline-none focus-visible:ring focus-visible:ring-ng70 focus-visible:ring-opacity-75"
        :class="{ '!text-ng800': open }"
      >
        {{ locale.toUpperCase() }}
        <DownArrow :rotate="open" icon="dashicons:arrow-down-alt2" size="12" />
      </HeadlessListboxButton>
      <DropdownTransition>
        <HeadlessListboxOptions
          class="absolute -left-9 z-10 mt-4 max-h-60 w-[100px] max-w-sm overflow-auto bg-white py-2 shadow-dropdown desktopLarge:left-0"
        >
          <HeadlessListboxOption
            v-slot="{ active, selected }"
            v-for="locale in locales"
            :key="locale.code"
            :value="locale.code"
          >
            <button
              class="w-full px-2 py-2 text-left text-ng600 hover:bg-ng20 focus:outline-none focus-visible:ring focus-visible:ring-ng70 focus-visible:ring-opacity-75 desktopLarge:text-center"
              :class="{
                'bg-ng20': active,
                'font-bold text-ng800': selected,
              }"
            >
              {{ locale.name }}
            </button>
          </HeadlessListboxOption>
        </HeadlessListboxOptions>
      </DropdownTransition>
    </div>
  </HeadlessListbox>
</template>
<script setup lang="ts">
// import { LocaleObject } from "@nuxtjs/i18n/dist/runtime/composables";

const { locale, locales, setLocale } = useI18n();

const router = useRouter();
const route = useRoute();
const switchLocalePath = useSwitchLocalePath();

const selectedLanguage = ref(locale.value);

const switchLocale = (locale: string) => {
  selectedLanguage.value = locale;
  router.replace({ path: switchLocalePath(locale), query: route.query, hash: route.hash, params: route.params });
  setLocale(locale);
};
</script>
<style lang="scss" scoped></style>
