<template>
  <form action="" @submit.prevent="search" class="search-form relative ml-[6px] w-full bg-white shadow-dropdown">
    <input
      type="text"
      v-model="searchStore.searchInput"
      class="h-full w-full rounded-full px-2.5 py-3 pr-12 text-sm text-n300 placeholder:text-link-color-green placeholder:opacity-30 focus:bg-white focus:outline-none"
      :placeholder="$t('searchPlaceholder')"
    />
    <button
      class="absolute top-1/2 right-2.5 flex h-8 w-8 -translate-y-1/2 items-center justify-center rounded-full bg-ng400 p-2 text-white transition duration-200 ease-out hover:bg-ng600"
    >
      <Icon name="akar-icons:search" size="15px" />
    </button>
  </form>
</template>
<script setup lang="ts">
import { useSearch } from "~/stores/search";
const searchStore = useSearch();
const { t: $t } = useI18n();

const router = useRouter();

const props = defineProps<{
  drawer?: boolean;
}>();

const emit = defineEmits(["close"]);

function search() {
  if (searchStore.searchInput) {
    if (props.drawer) {
      router.push({
        name: "search",
        query: { q: searchStore.searchInput },
      });
      emit("close");
    } else {
    }
  } else {
    if (!props.drawer) emit("close");
  }
}
</script>
<style lang="scss" scoped></style>
