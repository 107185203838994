<template>
  <header class="relative z-50 py-[10px] shadow-navbar-mobile laptop:shadow-navbar">
    <GridWrapper>
      <div class="z-10 flex items-center justify-between">
        <nav class="flex items-center [&>*:not(:first-child)]:ml-2 [&>*:not(:first-child)]:desktop:!ml-10">
          <SearchDropdown class="hidden laptop:block" />
          <button class="!ml-0 flex items-center laptop:hidden" @click="$emit('triggerSideDrawer')">
            <Icon name="akar-icons:search" size="20" />
          </button>
          <DrawerBtn class="!ml-6 flex items-center laptop:hidden" @click="$emit('triggerSideDrawer')" />
          <DropdownPanel class="hidden laptop:block" :title="$t('products')" :children="productLinks" />
          <HeaderNavigationLink class="hidden laptop:block" :to="{ name: 'brands' }" />
          <HeaderNavigationLink class="hidden laptop:block" :to="{ name: 'for-business' }" />
        </nav>
        <NuxtLink
          :to="localePath({ name: 'index' })"
          class="absolute left-1/2 z-20 max-w-[216px] -translate-x-1/2 laptop:min-w-[110px] desktop:top-[14px] desktop:h-[80px] desktop:w-full desktop:min-w-[125px]"
        >
          <img
            class="h-full max-h-[39px] w-full max-w-[104px] object-contain transition duration-500 ease-out laptop:max-h-[50px] laptop:max-w-[133px] desktop:h-[80px] desktop:max-h-full desktop:w-[216px] desktop:max-w-full"
            src="~/assets/img/logo-header.png"
            width="216"
            height="80"
            alt="Intercomm Foods S.A. Logo"
            draggable="false"
          />
        </NuxtLink>

        <nav class="flex items-center [&>*:not(:first-child)]:ml-2 [&>*:not(:first-child)]:desktop:!ml-10">
          <HeaderNavigationLink class="hidden laptop:block" :to="{ name: 'company' }" />
          <HeaderNavigationLink class="hidden laptop:block" :to="{ name: 'news' }" />
          <HeaderNavigationLink class="py-2 px-5 !text-xs laptop:!text-base" :to="{ name: 'contact' }" bordered />
          <LanguageSwitcher class="hidden laptop:block" />
        </nav>
      </div>
    </GridWrapper>
  </header>
</template>
<script setup>
import { productLinks } from "~/utilities/product-links";

const localePath = useLocalePath();
</script>
<style lang="scss" scoped></style>
