<template>
  <NuxtLink
    :to="
      localePath({
        name: 'brands-product',
        params: { product: `${props.product?.slug}` },
        query: {
          id: `${props.product?.id}`,
        },
      })
    "
    class="menu-item transition duration-200 ease-out hover:text-ng800"
    @click="$emit('clickedResult')"
  >
    {{ props.product.name }}
  </NuxtLink>
</template>
<script setup lang="ts">
const props = defineProps<{
  product: ProductResult;
}>();

defineEmits(["clickedResult"]);

const localePath = useLocalePath();
</script>
<style lang="scss" scoped></style>
