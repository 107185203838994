<template>
  <div>
    <Html :lang="head?.htmlAttrs?.lang" :dir="head?.htmlAttrs?.dir">
      <!--      <Head>
        <template v-for="link in head.link" :key="link.hid">
          <Link :id="link.hid" :rel="link.rel" :href="link.href" :hreflang="link.hreflang" />
        </template>
        <template v-for="meta in head.meta" :key="meta.id">
          <Meta :id="meta.id" :property="meta.property" :content="meta.content" />
        </template>
      </Head>-->
      <Body>
        <div class="flex min-h-[100vh] flex-col">
          <HeaderAppHeader @triggerSideDrawer="triggerSideDrawer" />
          <LoadingBar ref="loadingBar" />
          <notifications position="top center" classes="intercomm-notification" />
          <NuxtLayout>
            <NuxtPage
              :transition="{
                name: 'page',
                mode: 'out-in',
                onBeforeEnter,
                onEnter,
                onAfterEnter,
                onLeave,
              }"
              :key="key"
              class="relative min-h-[calc(100vh_-_58px)] flex-auto overflow-hidden"
            />
          </NuxtLayout>

          <DrawerSideDrawer :open="openSideDrawer" @triggerSideDrawer="triggerSideDrawer" />
          <FooterAppFooterDesktop class="hidden laptop:grid" />
          <FooterAppFooterMobile class="flex laptop:hidden" />
        </div>
      </Body>
    </Html>
  </div>
</template>

<script setup lang="ts">
import gsap from "gsap";
import { useLocaleChanging } from "~~/stores/global";
import { useSearch } from "./stores/search";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";
import { CustomEase } from "gsap/CustomEase";
import { toggleScrollBar } from "~~/utilities/toggleScrollBar";
import { LoadingBar } from "#components";
import { LoadingBarKey } from "~/symbols/symbols";
import Lenis from "@studio-freight/lenis";
import { marked } from "marked";

marked.use({
  breaks: true,
});

if (process.client) {
  const lenis = new Lenis();

  lenis.on("scroll", ScrollTrigger.update);

  gsap.ticker.add((time) => {
    lenis.raf(time * 1000);
  });

  gsap.registerPlugin(ScrollTrigger);
  gsap.registerPlugin(MotionPathPlugin);
  gsap.registerPlugin(CustomEase);

  provide("lenis", lenis);
}

const loadingBar = ref<InstanceType<typeof LoadingBar> | null>(null);

provide(LoadingBarKey, loadingBar);

const loading = ref(false);
const loadingTimeout = ref<NodeJS.Timeout | null>(null);
const searchStore = useSearch();

const router = useRouter();
const localeChangingStore = useLocaleChanging();

const openSideDrawer = ref(false);

const head = useLocaleHead({
  addDirAttribute: true,
  identifierAttribute: "id",
  addSeoAttributes: true,
});

const { t: $t, finalizePendingLocaleChange } = useI18n();

const onBeforeEnter = async () => {
  await finalizePendingLocaleChange();
  localeChangingStore.localeChanging = false;
};

const onEnter = async () => {
  localeChangingStore.pageTransitionEnded = true;
  clearTimeout(loadingTimeout.value as NodeJS.Timeout);
  // loading.value = false;
  loadingBar.value?.indicator.finish();
};

const onAfterEnter = async () => {
  loading.value = false;
};

const onLeave = async () => {
  localeChangingStore.pageTransitionEnded = false;
  clearTimeout(loadingTimeout.value as NodeJS.Timeout);
  loadingTimeout.value = setTimeout(() => {
    loading.value = true;
  }, 200);
  loadingBar.value?.indicator.start();
};

function triggerSideDrawer() {
  toggleScrollBar(!openSideDrawer.value);
  openSideDrawer.value = !openSideDrawer.value;
  searchStore.clearInput();
}

const key = ref(0);
const messages = [
  "Uncaught NotFoundError: Failed to execute 'insertBefore' on 'Node': The node before which the new node is to be inserted is not a child of this node.", // chromium based
  "NotFoundError: The object can not be found here.", // safari
];

if (typeof window !== "undefined") {
  // @ts-expect-error using arbitrary window key
  if (!window.__vue5513) {
    window.addEventListener("error", (event) => {
      if (messages.includes(event.message)) {
        event.preventDefault();
        console.warn("Rerendering layout because of https://github.com/vuejs/core/issues/5513");
        key.value++;
      }
    });
  }
  // @ts-expect-error using arbitrary window key
  window.__vue5513 = true;
}
</script>

<style>
.page-enter-active,
.page-leave-active {
  transition: all 0.3s ease-out;
}
.page-enter-from,
.page-leave-to {
  opacity: 0;
  transform: translateY(5px);
}

.slideFade-enter-active,
.slideFade-leave-active {
  transition: all 0.3s ease-out;
}
.slideFade-enter-from,
.slideFade-leave-to {
  opacity: 0;
  transform: translateY(5px);
}
</style>
