<template>
  <NuxtLink
    class="menuItems link relative overflow-visible whitespace-nowrap px-2 leading-[22px] text-ng600 hover:text-ng800 focus:outline-none focus-visible:ring focus-visible:ring-ng70 focus-visible:ring-opacity-75 laptop:px-5"
    :class="bordered"
    :to="localePath(props.to)"
  >
    <span class="leading-[1.2]" :class="{ underlined: !props.bordered }">
      {{ $t(`pages.${props.to.name}.title`) }}</span
    >
  </NuxtLink>
</template>
<script setup lang="ts">
const props = defineProps({
  to: {
    type: Object,
    required: true,
  },
  bordered: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const { t: $t } = useI18n();

const localePath = useLocalePath();

const bordered = computed(() => {
  return {
    "border border-ng70 hover:border-ng60": props.bordered,
    "py-2": props.bordered,
    "py-3": !props.bordered,
  };
});
</script>
<style lang="scss" scoped>
.link > .underlined {
  padding-bottom: 2px;
  &::after {
    content: "";
    width: 100%;
    height: 8px;
    position: absolute;
    background: theme("colors.ng10");
    left: 0;
    bottom: -11px;
    z-index: -1;
    transform: scaleX(0);
    transform-origin: right;

    transition: transform 0.22s ease-out;
  }
}

.link {
  &:hover {
    > .underlined::after {
      transform: scaleX(1);
      transform-origin: left;

      // background: theme("colors.ng800");
    }
  }

  &.router-link-active {
    > .underlined::after {
      transform: scaleX(1);
      // background: theme("colors.link-color-green");
    }
  }
}
</style>
