<template>
  <div class="flex items-center justify-center pt-4 laptop:pl-12">
    <picture>
      <source media="(min-width:1024px)" srcset="../../assets/img/logo-footer.png" />
      <img class="min-h-[45px] min-w-[70px]" src="../../assets/img/logo-small.png" :alt="$t('company-logo')" />
    </picture>
    <img
      class="ml-[23px] h-[60px] w-[60px] tablet:ml-[146px] min-[640px]:ml-[70px] min-[657px]:ml-[100px] laptop:ml-[43px] laptop:h-[82px] laptop:w-[82px]"
      src="../../assets/img/brc.png"
    />
    <img
      class="ml-[23px] h-[60px] w-[60px] min-[640px]:ml-[70px] min-[660px]:ml-[146px] laptop:ml-[43px] laptop:h-[82px] laptop:w-[82px]"
      src="../../assets/img/ifs.png"
    />
    <img
      class="ml-[23px] h-[60px] w-[60px] min-[640px]:ml-[70px] min-[660px]:ml-[146px] laptop:ml-[43px] laptop:h-[82px] laptop:w-[82px]"
      src="../../assets/img/iso.png"
    />
  </div>
</template>
<script setup lang="ts">
const { t: $t } = useI18n();
</script>
<style lang="scss" scoped></style>
