<template>
  <NuxtLink
    :to="localePath({ name: props.page.seo.pageLink })"
    class="menu-item group transition duration-200 ease-out hover:text-ng800"
    @click="$emit('clickedResult')"
  >
    <div>{{ props.page.title }}</div>
    <div class="mt-1.5 text-ng60 transition duration-200 ease-out empty:hidden group-hover:text-ng900">
      {{ props.page.description }}
    </div>
  </NuxtLink>
</template>
<script setup lang="ts">
const props = defineProps<{
  page: PageResult;
}>();
defineEmits(["clickedResult"]);

const localePath = useLocalePath();
</script>
<style lang="scss" scoped></style>
