<template>
  <HeadlessPopover v-slot="{ open, close }">
    <HeadlessPopoverButton
      class="group -mx-5 flex items-center bg-transparent py-3 px-5 font-sans text-ng600 hover:text-ng800 focus:outline-none"
      :class="{ '!text-ng800': open }"
      aria-label="Search"
    >
      <Icon name="akar-icons:search" size="20px" />
    </HeadlessPopoverButton>
    <DropdownTransition @after-leave="searchStore.clearInput()">
      <HeadlessPopoverPanel
        class="absolute left-0 top-full z-20 mt-4 flex w-full transform flex-col overflow-hidden py-2 transition duration-200 ease-out"
        :class="{ 'bg-light': searchStore.searchInput !== '' }"
        @click="handlePanelClick($event, close)"
      >
        <GridsTwelveGrid class="z-20 pb-4">
          <SearchInputField @close="close" class="col-span-2" />
        </GridsTwelveGrid>
        <SearchResults class="bg-light" @clicked-result="close" />
      </HeadlessPopoverPanel>
    </DropdownTransition>
  </HeadlessPopover>
</template>
<script setup lang="ts">
import { useSearch } from "~/stores/search";

const searchStore = useSearch();

function handlePanelClick(e: Event, close: () => void) {
  if (e.target && e.target instanceof Element && searchStore.searchInput === "" && !e.target.closest(".search-form")) {
    //if e.target is an anchor link or a button, close the dropdown

    close();
  }
}
</script>
<style lang="scss" scoped></style>
