<template>
  <div class="nuxt-loading-indicator" :style="(styleObject as CSSProperties)"></div>
</template>
<script setup lang="ts">
import { CSSProperties } from "vue";
const props = defineProps({
  duration: {
    type: Number,
    default: 2000,
  },
  throttle: {
    type: Number,
    default: 0,
  },
  height: {
    type: Number,
    default: 3,
  },
  color: {
    type: [String, Boolean],
    default: "repeating-linear-gradient(to right,#00dc82 0%,#34cdfe 50%,#0047e1 100%)",
  },
});

const indicator = useLoadingIndicator({
  duration: props.duration,
  throttle: props.throttle,
});

const styleObject = computed(() => {
  return {
    position: "fixed",
    top: 0,
    right: 0,
    left: 0,
    pointerEvents: "none",
    width: `${indicator.progress.value}%`,
    height: `${props.height}px`,
    opacity: indicator.isLoading.value ? 1 : 0,
    background: props.color || undefined,
    transition: "width 0.1s, height 0.4s, opacity 0.4s",
    zIndex: 999999,
  };
});

const startCount = ref(0);

function useLoadingIndicator(opts: { duration: number; throttle: number }) {
  const progress = ref(0);
  const isLoading = ref(false);

  const step = computed(() => {
    const step = 10000 / (opts.duration * (startCount.value ?? 1));
    const stepDecrease = (progress.value / 95) * step;

    return step - stepDecrease;
  });

  let _timer: any = null;
  let _throttle: any = null;

  function start() {
    startCount.value += 1;

    clear();
    progress.value = 0;
    if (opts.throttle && process.client) {
      _throttle = setTimeout(() => {
        isLoading.value = true;
        _startTimer();
      }, opts.throttle);
    } else {
      isLoading.value = true;

      _startTimer();
    }
  }
  function finish() {
    startCount.value -= 1;

    if (startCount.value > 0) {
      return;
    }

    progress.value = 100;
    _hide();
  }

  function clear() {
    clearInterval(_timer);
    clearTimeout(_throttle);
    _timer = null;
    _throttle = null;
  }

  function _increase(num: number) {
    progress.value = Math.min(100, progress.value + num);
  }

  function _hide() {
    clear();
    if (process.client) {
      setTimeout(() => {
        isLoading.value = false;
        setTimeout(() => {
          progress.value = 0;
        }, 400);
      }, 500);
    }
  }

  function _startTimer() {
    if (process.client) {
      _timer = setInterval(() => {
        _increase(step.value);
      }, 100);
    }
  }

  return {
    progress,
    isLoading,
    start,
    finish,
    clear,
  };
}

defineExpose({
  indicator,
});

const nuxtApp = useNuxtApp();
nuxtApp.hook("page:start", indicator.clear);
onBeforeUnmount(() => {
  indicator.clear;
});
</script>
<style lang="scss" scoped></style>
