<template>
  <div
    class="grid w-full grid-cols-[1fr_minmax(0px,1740px)_1fr] gap-x-5 desktop:gap-[3rem] desktopLarge:gap-x-[5.625rem]"
  >
    <div class="relative col-start-2 w-full">
      <slot />
    </div>
  </div>
</template>
<script setup lang="ts"></script>
<style lang="scss" scoped></style>
