<template>
  <component
    :is="props.type === 'button' ? 'button' : props.to ? NuxtLink : 'button'"
    :to="props.type !== 'button' ? props.to : ''"
    class="group inline-flex cursor-pointer select-none items-center disabled:opacity-60"
  >
    <span
      class="relative text-sm font-medium italic text-ng400 transition duration-200 ease-out after:absolute after:left-0 after:-bottom-1 after:h-[2px] after:w-full after:origin-left after:scale-x-0 after:bg-[#F1F9F3] after:transition after:duration-300 after:ease-out group-hover:text-ng700 group-hover:after:scale-x-100 group-hover:after:bg-ng200 laptop:text-lg"
      >{{ props.text }}</span
    >
    <svg
      :class="{
        'mb-5 -rotate-90 group-hover:-translate-y-1': props.isRotated,
        'group-hover:translate-x-1': !props.isRotated,
      }"
      class="z-10 ml-8 mb-2.5 transition duration-200 ease-out laptop:h-4 laptop:w-[55px]"
      width="44"
      height="13"
      viewBox="0 0 44 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 11.8239H41L27.9904 1.17969" stroke="#6B776F" stroke-width="2" />
    </svg>
    <svg
      :class="{ '-ml-10 mb-10 laptop:-ml-[50px] laptop:mb-12': props.isRotated }"
      class="-ml-8 transition duration-200 ease-out laptop:h-[60px] laptop:w-[60px]"
      width="48"
      height="48"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        class="transition duration-200 ease-out group-hover:fill-ng20 group-enabled:focus:fill-ng100"
        cx="30"
        cy="30"
        r="30"
        fill="#F1F9F3"
      />
    </svg>
  </component>
</template>

<script lang="ts" setup>
const props = defineProps({
  text: {
    type: String,
    required: true,
  },
  to: {
    type: String,
    required: false,
  },
  type: {
    type: String,
    required: false,
  },
  isRotated: {
    type: Boolean,
    default: false,
    required: false,
  },
});

const NuxtLink = resolveComponent("NuxtLink");
</script>
<style lang="scss" scoped></style>
