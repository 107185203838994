export const toggleScrollBar = (value: boolean) => {
  if (value) {
    const scrollBarWidth = window.innerWidth - document.documentElement.clientWidth;
    if (scrollBarWidth > 30) return;

    document.body.style.paddingRight = `${scrollBarWidth}px`;
    document.body.style.overflow = "hidden";
  } else {
    setTimeout(() => {
      document.body.style.paddingRight = "";
      document.body.style.overflow = "";
    }, 200);
  }
};
