<template>
  <span class="inline-flex items-center justify-center">
    <Icon
      :name="props.icon"
      class="ml-0.5 transform transition duration-200 ease-out"
      :class="{
        'rotate-180': props.rotate,
        [size]: true,
      }"
      :size="props.size"
    />
  </span>
</template>
<script setup lang="ts">
const props = defineProps({
  rotate: {
    type: Boolean,
    default: false,
    required: false,
  },
  icon: {
    type: String,
    default: "simple-line-icons:arrow-down",
    required: false,
  },
  size: {
    type: String,
    default: "16",
    required: false,
  },
});

const size = computed(() => {
  return `w-[${props.size}px] h-[${props.size}px]`;
});
</script>
<style lang="scss" scoped></style>
