export const links = [
  {
    id: 0,
    title: "FooterLinks.PrivateLabel",
    to: { name: "for-business-private-label" },
  },
  {
    id: 1,
    title: "FooterLinks.PackagingOptions",
    to: { name: "products-packaging" },
  },
  {
    id: 2,
    title: "FooterLinks.FoodService",
    to: { name: "for-business-food-service" },
  },
  {
    id: 3,
    title: "FooterLinks.BrandedProducts",
    to: { name: "brands" },
  },
  {
    id: 4,
    title: "FooterLinks.Logistics",
    to: { name: "products-packaging" },
  },
];
