import { InjectionKey, Ref } from "vue";
import {
  HomePageQuery,
  ForBusinessPageQuery,
  PrivateLabelPageQuery,
  FoodServicePageQuery,
  CompanyPage1Query,
  CompanyPage2Query,
  GetBrandsAndProductsQuery,
  BrandWithProductsFragment,
} from "#gql";
import { LoadingBar } from "#components";

export const HomepageDataKey: InjectionKey<Ref<HomePageQuery | null>> = Symbol("homepageData");
export const ForBusinesspageDataKey: InjectionKey<Ref<ForBusinessPageQuery | null>> = Symbol("forBusinessPageData");
export const PrivateLabelpageDataKey: InjectionKey<Ref<PrivateLabelPageQuery | null>> = Symbol("PrivateLabelPageData");
export const FoodServicepageDataKey: InjectionKey<Ref<FoodServicePageQuery | null>> = Symbol("FoodServicePageData");
export const Companypage1DataKey: InjectionKey<Ref<CompanyPage1Query | null>> = Symbol("companypage1Data");
export const Companypage2DataKey: InjectionKey<Ref<CompanyPage2Query | null>> = Symbol("companypage2Data");
export const SelectedBrandKey: InjectionKey<Ref<BrandWithProductsFragment | null>> = Symbol("selectedBrand");
export const BrandsKey: InjectionKey<Ref<GetBrandsAndProductsQuery | null>> = Symbol("brands");
export const LoadingBarKey: InjectionKey<Ref<InstanceType<typeof LoadingBar> | null>> = Symbol("loadingBar");
