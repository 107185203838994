<template>
  <div
    class="fixed top-0 left-0 z-50 h-full w-full max-w-[320px] -translate-x-full bg-white shadow-lg transition duration-300 ease-out laptop:hidden"
    :class="{
      '!translate-x-0': props.open,
    }"
  >
    <div class="flex items-center justify-between px-4 py-2">
      <img
        src="~/assets/img/logo-header.png"
        class="h-[35px] w-[90px]"
        alt="Intercomm Foods S.A."
        width="90"
        height="35"
      />
      <button class="text-gray-600 focus:outline-none" @click="$emit('triggerSideDrawer')">
        <Icon name="ep:back" size="22px" />
      </button>
    </div>
    <div class="my-4 h-[44px] px-4">
      <SearchInputField class="h-full shadow-dropdown" drawer @close="$emit('triggerSideDrawer')" />
    </div>
    <div
      class="flex flex-1 flex-col justify-between px-4 transition duration-200 ease-out"
      :class="{
        'opacity-0': localeChangingStore.localeChanging,
      }"
    >
      <HeadlessDisclosure v-slot="{ open, close }">
        <HeadlessDisclosureButton
          :data-close="close"
          class="disclosure-btn flex w-full items-center px-2.5 py-2.5 text-left text-ng600 hover:bg-ng20 focus:outline-none focus-visible:ring focus-visible:ring-ng70 focus-visible:ring-opacity-75"
        >
          <span>{{ $t("Products") }}</span>
          <span class="mx-0.5 inline-flex w-[9px] justify-center text-ng70">|</span>
          <DownArrow :rotate="open" />
        </HeadlessDisclosureButton>

        <HeadlessDisclosurePanel
          class="panel max-h-0 overflow-hidden opacity-0 transition-all duration-200"
          :class="open && 'max-h-[500px] !overflow-visible opacity-100'"
          static
        >
          <div class="flex flex-col px-5 text-ng600">
            <HeadlessDisclosure>
              <NuxtLink
                :to="localePath({ name: 'products' })"
                @click="$emit('triggerSideDrawer'), closeDiscoluseButtons(), close()"
                class="flex w-full px-5 py-2 text-left text-ng600 hover:bg-ng20 focus:outline-none focus-visible:ring focus-visible:ring-ng70 focus-visible:ring-opacity-75"
                >{{ $t("all-products") }}</NuxtLink
              >
            </HeadlessDisclosure>
            <HeadlessDisclosure
              v-for="link in dataRef?.productTypes?.data"
              :key="JSON.stringify(link)"
              v-slot="{ open }"
            >
              <HeadlessDisclosureButton
                v-if="link.attributes?.product_type_options?.data?.length"
                class="disclosure-btn flex w-full items-center justify-between px-5 py-2 text-left text-ng600 hover:bg-ng20 focus:outline-none focus-visible:ring focus-visible:ring-ng70 focus-visible:ring-opacity-75"
              >
                {{ link.attributes.name }}
                <DownArrow :rotate="open" />
              </HeadlessDisclosureButton>
              <HeadlessDisclosurePanel
                v-if="link.attributes?.product_type_options?.data?.length"
                class="panel max-h-0 overflow-hidden opacity-0 transition-all duration-200"
                :class="open && 'max-h-[500px] !overflow-visible opacity-100'"
                static
              >
                <div class="flex flex-col px-5 text-ng600">
                  <HeadlessDisclosureButton class="hidden" ref="discoluseButtons"> </HeadlessDisclosureButton>
                  <NuxtLink
                    :to="localePath({ name: 'products-type', params: { type: link.attributes?.slug as string } })"
                    @click="$emit('triggerSideDrawer'), closeDiscoluseButtons(), close()"
                    class="w-full px-5 py-1.5 text-left hover:bg-ng20 focus:outline-none focus-visible:ring focus-visible:ring-ng70 focus-visible:ring-opacity-75"
                    >{{ $t("all") }}</NuxtLink
                  >
                  <NuxtLink
                    v-for="child in link.attributes?.product_type_options?.data"
                    :key="child.attributes?.slug"
                    :to="
                      localePath({
                        name: 'products-type-subtype',
                        params: { type: link.attributes.slug as string, subtype: child.attributes?.slug as string },
                      })
                    "
                    class="w-full px-5 py-1.5 text-left hover:bg-ng20 focus:outline-none focus-visible:ring focus-visible:ring-ng70 focus-visible:ring-opacity-75"
                    @click="$emit('triggerSideDrawer'), closeDiscoluseButtons(), close()"
                  >
                    {{ child.attributes?.name }}
                  </NuxtLink>
                </div>
              </HeadlessDisclosurePanel>
              <NuxtLink
                v-else
                @click="$emit('triggerSideDrawer')"
                :to="localePath({ name: 'products-type', params: { type: link?.attributes?.slug as string } })"
                class="flex w-full px-5 py-2 text-left text-ng600 hover:bg-ng20 focus:outline-none focus-visible:ring focus-visible:ring-ng70 focus-visible:ring-opacity-75"
                >{{ link.attributes?.name }}</NuxtLink
              >
            </HeadlessDisclosure>
          </div>
        </HeadlessDisclosurePanel>
      </HeadlessDisclosure>
      <NuxtLink
        v-for="link in sideDrawerLinks"
        :key="link.id"
        :to="localePath(link.to)"
        @click="$emit('triggerSideDrawer')"
        class="w-full py-2.5 px-2.5 text-ng600 hover:bg-ng20 focus:outline-none focus-visible:ring focus-visible:ring-ng70 focus-visible:ring-opacity-75"
      >
        {{ $t(link.title) }}
      </NuxtLink>
      <HeadlessDisclosure v-slot="{ open, close }">
        <HeadlessDisclosureButton
          class="flex w-full items-center px-2.5 py-2.5 text-left text-ng600 hover:bg-ng20 focus:outline-none focus-visible:ring focus-visible:ring-ng70 focus-visible:ring-opacity-75"
        >
          <span class="mr-2">{{ locale.toUpperCase() }}</span>
          <DownArrow :rotate="open" />
        </HeadlessDisclosureButton>
        <HeadlessDisclosurePanel
          class="panel max-h-0 overflow-hidden opacity-0 transition-all duration-200"
          :class="open && 'max-h-[500px] !overflow-visible opacity-100'"
          static
        >
          <div class="flex flex-col px-2.5 text-ng600">
            <a
              v-for="localeItem in locales"
              :key="localeItem.code"
              href="#"
              @click.prevent="switchLocale(localeItem.code, close)"
              class="flex w-full px-2.5 py-1.5 text-left text-ng600 hover:bg-ng20 focus:outline-none focus-visible:ring focus-visible:ring-ng70 focus-visible:ring-opacity-75"
              :class="localeItem.code === locale && 'font-semibold'"
              >{{ localeItem.name }}</a
            >
          </div>
        </HeadlessDisclosurePanel>
      </HeadlessDisclosure>
    </div>
  </div>

  <Teleport to="body">
    <div
      class="fixed inset-0 z-30 bg-[#000]/25 bg-opacity-50 transition-opacity duration-300 laptop:hidden"
      :class="{
        'pointer-events-none opacity-0': !props.open,
        'pointer-events-auto opacity-100': props.open,
      }"
      @click="$emit('triggerSideDrawer')"
    ></div>
  </Teleport>
</template>
<script setup lang="ts">
import { sideDrawerLinks } from "~/utilities/product-links";
import { useLocaleChanging } from "~/stores/global";
// import { LocaleObject } from "@nuxtjs/i18n/dist/runtime/composables";
const NuxtLink = resolveComponent("nuxt-link");

defineEmits<{
  (e: "triggerSideDrawer"): void;
}>();
const { t: $t } = useI18n();
const localePath = useLocalePath();

const props = defineProps<{
  open: boolean;
}>();
const { locale, locales, finalizePendingLocaleChange, setLocale } = useI18n();

await finalizePendingLocaleChange();

const { data } = await useAsyncGql({
  operation: "GetProductTypesAndSubtypes",
  variables: { locale: locale.value },
  options: {
    lazy: false,
  },
});

const dataRef = ref(data.value);

const discoluseButtons = ref<{ el: HTMLButtonElement }[]>([]);

const localeChangingStore = useLocaleChanging();

const router = useRouter();

function closeDiscoluseButtons() {
  if (!discoluseButtons.value) return;
  discoluseButtons.value.forEach((button) => {
    if (button.el.dataset.headlessuiState === "open") {
      button.el.click();
    }
  });
}
const route = useRoute();
const switchLocalePath = useSwitchLocalePath();

const switchLocale = async (newLocale: string, close: () => null) => {
  if (newLocale === locale.value) {
    close();

    return;
  }

  localeChangingStore.localeChanging = true;
  router.replace({ path: switchLocalePath(newLocale), query: route.query, hash: route.hash, params: route.params });

  setLocale(newLocale);
};

watch(locale, async () => {
  await finalizePendingLocaleChange();

  const data = await useAsyncGql({
    operation: "GetProductTypesAndSubtypes",
    variables: { locale: locale.value },
    options: {
      lazy: false,
    },
  });

  dataRef.value = data.data.value;
});
</script>
<style lang="scss" scoped></style>
