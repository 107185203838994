<template>
  <div class="mob flex flex-col bg-footer">
    <FooterIconsHeadingMobile />
    <FooterLargeBtn />
    <FooterNewsletterSection />
    <FooterProductLinks />
    <FooterProductionInfo />
  </div>
</template>
<script setup></script>
<style lang="scss" scoped></style>
