<template>
  <div class="grid w-full grid-cols-[1fr_minmax(0px,405px)_1fr]">
    <div class="col-span-2 col-start-1 flex flex-row items-start justify-between pt-8">
      <div class="-mx-3 -my-3 flex flex-wrap items-start justify-start pr-4">
        <img class="mx-3 my-3" src="../../assets/img/brc.png" alt="BRC" />
        <img class="mx-3 my-3" src="../../assets/img/ifs.png" alt="IFS" />
        <img class="mx-3 my-3" src="../../assets/img/iso.png" alt="ISO" />
      </div>
      <div class="flex min-w-[300px] flex-col">
        <div
          class="grid w-full grid-cols-2 justify-between gap-y-8 gap-x-[24px] self-start text-[13px] tablet:self-center"
        >
          <div class="flex flex-col gap-y-[1.5px]">
            <p class="pb-4 text-[13px] font-semibold leading-[18px] text-white">{{ $t("product-categories") }}</p>
            <div
              v-for="(productType, index) in footerData?.productTypes?.data"
              :key="index"
              class="text-[13px] font-normal leading-[18px]"
            >
              <NuxtLink
                :to="
                  localePath({
                    name: 'products-type',
                    params: { type: data?.productTypes?.data[index]?.attributes?.slug as string },
                  })
                "
                class="text-ng50 hover:underline"
                >{{ productType?.attributes?.name }}</NuxtLink
              >
            </div>
          </div>
          <div class="flex flex-col gap-y-[1.5px]">
            <p class="pb-4 text-[13px] font-semibold leading-[18px] text-white">{{ $t("b2b-options") }}</p>
            <div v-for="link in links" :key="link.id" class="text-[13px] font-normal leading-[18px]">
              <NuxtLink :to="localePath(link.to)" class="text-ng50 hover:underline">{{ $t(link.title) }}</NuxtLink>
            </div>
          </div>
          <NuxtLink
            :to="localePath({ name: 'products-packaging' })"
            class="col-start-1 row-start-2 text-xs text-white hover:underline"
            >{{ $t("cookie-preferences") }}</NuxtLink
          >
          <NuxtLink
            :to="localePath({ name: 'products-packaging' })"
            class="col-start-2 row-start-2 text-xs text-white hover:underline"
            >{{ $t("terms-of-use") }}</NuxtLink
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { links } from "../../utilities/footer-links";
const NuxtLink = resolveComponent("nuxt-link");

const { locale, finalizePendingLocaleChange } = useI18n();

const localePath = useLocalePath();

await finalizePendingLocaleChange();

const { data } = await useAsyncGql({
  operation: "GetProductTypesAndSubtypes",
  variables: { locale: locale.value },
  options: {
    lazy: false,
  },
});

const footerData = ref(data.value);

watch(locale, async () => {
  await finalizePendingLocaleChange();
  const { data } = await useAsyncGql({
    operation: "GetProductTypesAndSubtypes",
    variables: { locale: locale.value },
    options: {
      lazy: false,
    },
  });
  footerData.value = data.value;
});
</script>
<style lang="scss" scoped>
.router-link-active {
  color: #fff !important;
}
</style>
