<template>
  <Transition name="fade200">
    <div v-if="searchStore.searchInput !== ''" class="mt-5 min-h-[456px] w-full shadow-navbar">
      <GridsTwelveGrid>
        <Transition name="fade" appear>
          <div class="col-span-2 flex flex-col" v-if="localeFilteredProductResults?.length">
            <div class="subtitle">{{ $t("found-products") }}</div>
            <div class="mt-10 flex flex-col space-y-1.5">
              <SearchResultProduct
                v-for="product in localeFilteredProductResults"
                :key="product.id"
                :product="product"
                @clicked-result="emitClickedResultWithDelay(400)"
              />
            </div>
            <NextBtn
              :to="localePath({ name: 'products' })"
              :text="$t('seeAllProducts')"
              class="mt-6"
              @click="emitClickedResultWithDelay(400)"
            />
          </div>
        </Transition>
        <Transition name="fade" appear>
          <div class="col-span-7 col-start-4 flex flex-col pb-4" v-if="localeFilteredPagesResults?.length">
            <div class="subtitle ml-auto">{{ $t("found-pages") }}</div>
            <div class="mt-10 flex flex-col space-y-6 px-2">
              <SearchResultPage
                v-for="page in localeFilteredPagesResults"
                :key="page.id"
                :page="page"
                @click="emitClickedResultWithDelay(400)"
              />
            </div>
            <NextBtn
              :to="localePath({ name: 'search', query: { q: searchStore.searchInput, pagesOnly: 'true' } })"
              :text="$t('seeAllResults')"
              class="mt-6 ml-auto"
              @click="emitClickedResultWithDelay(400)"
            />
          </div>
          <!-- <div class="col-span-7 col-start-4 flex flex-col" v-else-if="!localeFilteredPagesResults.pending.value">
            {{ $t("there-are-no-results") }}
          </div> -->
        </Transition>
      </GridsTwelveGrid>
    </div>
  </Transition>
</template>
<script setup lang="ts">
import { useSearch } from "~/stores/search";
import { storeToRefs } from "pinia";

import { MeiliSearch } from "meilisearch";

const client = new MeiliSearch({
  host: process.server ? "localhost:7700" : window.location.origin + "/meili",
  apiKey: "5f7a658abda24082d3291b01bb6d7bc8a4fddc7ffdbd31cc8bd3f54d91040549",
});

const emit = defineEmits(["clickedResult"]);

const { t: $t } = useI18n();
const searchStore = useSearch();

const { searchInput } = storeToRefs(searchStore);

const localePath = useLocalePath();

function emitClickedResultWithDelay(delay: number) {
  setTimeout(() => {
    emit("clickedResult");
  }, delay);
}
const { locale } = useI18n();

const pagesIndex = client.index("pages");

const pagesResults = ref(null);

pagesResults.value = await useAsyncData(
  "pagesSearch",
  () => {
    return pagesIndex.search(searchStore.searchInput);
  },
  {
    lazy: true,
    watch: searchInput,
    immediate: false,
  }
);

const localeFilteredPagesResults = computed(() => {
  return pagesResults.value?.data?.hits?.filter((result: any) => {
    return result.locale === locale.value;
  });
});

const productsIndex = client.index("product");

const productResults = ref(null);

const localeFilteredProductResults = computed(() => {
  return productResults.value?.data?.hits?.filter((result: any) => {
    return result.locale === locale.value;
  });
});

productResults.value = await useAsyncData(
  "productsSearch",
  () => {
    return productsIndex.search(searchStore.searchInput);
  },
  {
    lazy: true,
    watch: searchInput,
    immediate: false,
  }
);
</script>
<style lang="scss" scoped></style>
