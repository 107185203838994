<template>
  <div id="footer" class="grid auto-rows-auto grid-cols-12 items-start bg-footer">
    <FooterLargeBtn class="col-span-6 col-start-1" />
    <FooterNewsletterSection class="col-span-6 col-start-7 h-full" />
    <FooterLogoFooter class="col-span-6 col-start-1" />
    <FooterProductLinksDesktop class="col-span-6 col-start-7" />
    <FooterProductionInfo class="col-span-12 mx-auto" />
  </div>
</template>
<script setup></script>
<style lang="scss" scoped></style>
