<template>
  <div class="flex flex-col px-5 laptop:self-start">
    <HeadlessDisclosure v-slot="{ open }">
      <HeadlessDisclosureButton
        class="disclosure-btn flex w-full items-center justify-between py-2 text-left font-medium text-ng30 focus:outline-none focus-visible:ring focus-visible:ring-ng70 focus-visible:ring-opacity-75"
      >
        {{ $t("product-categories") }}
        <DownArrow :rotate="open" />
      </HeadlessDisclosureButton>
      <HeadlessDisclosurePanel
        class="panel max-h-0 overflow-hidden opacity-0 transition-all duration-200"
        :class="open && 'max-h-[500px] !overflow-visible opacity-100'"
        static
      >
        <div class="flex flex-col items-start">
          <NuxtLink
            v-for="(productType, index) in data?.productTypes?.data"
            :key="index"
            :to="
              localePath({ name: 'products-type', params: { type: data?.productTypes?.data[index]?.attributes?.slug } })
            "
            class="w-full py-1.5 text-left text-ng50 hover:underline focus:outline-none focus-visible:ring focus-visible:ring-ng70 focus-visible:ring-opacity-75 active:underline"
            >{{ productType?.attributes?.name }}</NuxtLink
          >
        </div>
      </HeadlessDisclosurePanel>
    </HeadlessDisclosure>
    <HeadlessDisclosure v-slot="{ open }">
      <HeadlessDisclosureButton
        class="disclosure-btn flex w-full items-center justify-between py-2 text-left font-medium text-ng30 focus:outline-none focus-visible:ring focus-visible:ring-ng70 focus-visible:ring-opacity-75"
      >
        {{ $t("b2b-options") }}
        <DownArrow :rotate="open" />
      </HeadlessDisclosureButton>
      <HeadlessDisclosurePanel
        class="panel max-h-0 overflow-hidden opacity-0 transition-all duration-200"
        :class="open && 'max-h-[500px] !overflow-visible opacity-100'"
        static
      >
        <div class="flex flex-col items-start">
          <NuxtLink
            v-for="link in links"
            :key="link.id"
            :to="localePath(link.to)"
            class="w-full py-1.5 text-left text-ng50 hover:underline focus:outline-none focus-visible:ring focus-visible:ring-ng70 focus-visible:ring-opacity-75 active:underline"
            >{{ $t(link.title) }}</NuxtLink
          >
        </div>
      </HeadlessDisclosurePanel>
    </HeadlessDisclosure>

    <div class="flex pt-[10px]">
      <NuxtLink :to="localePath({ name: 'products-packaging' })" class="text-ng30">{{
        $t("cookie-preferences")
      }}</NuxtLink>
      <NuxtLink :to="localePath({ name: 'products-packaging' })" class="ml-auto text-ng30">{{
        $t("terms-of-use")
      }}</NuxtLink>
    </div>
  </div>
</template>
<script setup>
import { links } from "../../utilities/footer-links.ts";

const localePath = useLocalePath();

const { locale } = useI18n();

const { data } = await useAsyncGql({
  operation: "GetProductTypesAndSubtypes",
  variables: { locale: locale.value },
  options: {
    lazy: false,
  },
});
</script>
<style lang="scss" scoped>
.router-link-exact-active {
  color: #fff !important;
  text-decoration: underline;
}
</style>
