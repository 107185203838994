import { default as _91_46_46_46404_93OsmKXOinhZMeta } from "/home/ploi/intercomm.cgworks.eu/pages/[...404].vue?macro=true";
import { default as _91_46_46_46slug_93HEjAUM4IUzMeta } from "/home/ploi/intercomm.cgworks.eu/pages/[...slug].vue?macro=true";
import { default as _91product_93cBId7nbsLRMeta } from "/home/ploi/intercomm.cgworks.eu/pages/brands/[product].vue?macro=true";
import { default as indexMYczkMpdhWMeta } from "/home/ploi/intercomm.cgworks.eu/pages/brands/index.vue?macro=true";
import { default as brandsrqgM6vqY6NMeta } from "/home/ploi/intercomm.cgworks.eu/pages/brands.vue?macro=true";
import { default as indexQ8XasWYV3QMeta } from "/home/ploi/intercomm.cgworks.eu/pages/company/index.vue?macro=true";
import { default as companyabWxGtiUlHMeta } from "/home/ploi/intercomm.cgworks.eu/pages/company.vue?macro=true";
import { default as index8WbKIMH13HMeta } from "/home/ploi/intercomm.cgworks.eu/pages/contact/index.vue?macro=true";
import { default as contactJ997rGUc3MMeta } from "/home/ploi/intercomm.cgworks.eu/pages/contact.vue?macro=true";
import { default as food_45serviceLDOHzJRW5oMeta } from "/home/ploi/intercomm.cgworks.eu/pages/for-business/food-service.vue?macro=true";
import { default as indexYBCykBGH15Meta } from "/home/ploi/intercomm.cgworks.eu/pages/for-business/index.vue?macro=true";
import { default as private_45labelVPbWV0EDvCMeta } from "/home/ploi/intercomm.cgworks.eu/pages/for-business/private-label.vue?macro=true";
import { default as for_45businessVeARN2QDEcMeta } from "/home/ploi/intercomm.cgworks.eu/pages/for-business.vue?macro=true";
import { default as indexHaq2o8HcU5Meta } from "/home/ploi/intercomm.cgworks.eu/pages/index/index.vue?macro=true";
import { default as indexZFyk6u92rFMeta } from "/home/ploi/intercomm.cgworks.eu/pages/index.vue?macro=true";
import { default as _91newsId_93tUqESXcOGKMeta } from "/home/ploi/intercomm.cgworks.eu/pages/news/[newsId].vue?macro=true";
import { default as indexQNyMzmYNkhMeta } from "/home/ploi/intercomm.cgworks.eu/pages/news/index.vue?macro=true";
import { default as newsAacebisTRYMeta } from "/home/ploi/intercomm.cgworks.eu/pages/news.vue?macro=true";
import { default as _91subtype_93fwWFYg0Qu1Meta } from "/home/ploi/intercomm.cgworks.eu/pages/products/[type]/[subtype].vue?macro=true";
import { default as indexrE2QPjLWadMeta } from "/home/ploi/intercomm.cgworks.eu/pages/products/[type]/index.vue?macro=true";
import { default as indexoDXdLIOxiWMeta } from "/home/ploi/intercomm.cgworks.eu/pages/products/index.vue?macro=true";
import { default as packagingvl2mONS5J3Meta } from "/home/ploi/intercomm.cgworks.eu/pages/products/packaging.vue?macro=true";
import { default as productss0xPw5u0w0Meta } from "/home/ploi/intercomm.cgworks.eu/pages/products.vue?macro=true";
import { default as indexsYLB25eS7SMeta } from "/home/ploi/intercomm.cgworks.eu/pages/search/index.vue?macro=true";
import { default as searchq5oXB2zwEbMeta } from "/home/ploi/intercomm.cgworks.eu/pages/search.vue?macro=true";
import { default as strapirhHU3hjPTUMeta } from "/home/ploi/intercomm.cgworks.eu/pages/strapi.vue?macro=true";
export default [
  {
    name: _91_46_46_46404_93OsmKXOinhZMeta?.name ?? "404",
    path: _91_46_46_46404_93OsmKXOinhZMeta?.path ?? "/:404(.*)*",
    meta: _91_46_46_46404_93OsmKXOinhZMeta || {},
    alias: _91_46_46_46404_93OsmKXOinhZMeta?.alias || [],
    redirect: _91_46_46_46404_93OsmKXOinhZMeta?.redirect || undefined,
    component: () => import("/home/ploi/intercomm.cgworks.eu/pages/[...404].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46404_93OsmKXOinhZMeta?.name ?? "404___en",
    path: _91_46_46_46404_93OsmKXOinhZMeta?.path ?? "/en/:404(.*)*",
    meta: _91_46_46_46404_93OsmKXOinhZMeta || {},
    alias: _91_46_46_46404_93OsmKXOinhZMeta?.alias || [],
    redirect: _91_46_46_46404_93OsmKXOinhZMeta?.redirect || undefined,
    component: () => import("/home/ploi/intercomm.cgworks.eu/pages/[...404].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46404_93OsmKXOinhZMeta?.name ?? "404___el",
    path: _91_46_46_46404_93OsmKXOinhZMeta?.path ?? "/el/:404(.*)*",
    meta: _91_46_46_46404_93OsmKXOinhZMeta || {},
    alias: _91_46_46_46404_93OsmKXOinhZMeta?.alias || [],
    redirect: _91_46_46_46404_93OsmKXOinhZMeta?.redirect || undefined,
    component: () => import("/home/ploi/intercomm.cgworks.eu/pages/[...404].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93HEjAUM4IUzMeta?.name ?? "slug",
    path: _91_46_46_46slug_93HEjAUM4IUzMeta?.path ?? "/:slug(.*)*",
    meta: _91_46_46_46slug_93HEjAUM4IUzMeta || {},
    alias: _91_46_46_46slug_93HEjAUM4IUzMeta?.alias || [],
    redirect: _91_46_46_46slug_93HEjAUM4IUzMeta?.redirect || undefined,
    component: () => import("/home/ploi/intercomm.cgworks.eu/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93HEjAUM4IUzMeta?.name ?? "slug___en",
    path: _91_46_46_46slug_93HEjAUM4IUzMeta?.path ?? "/en/:slug(.*)*",
    meta: _91_46_46_46slug_93HEjAUM4IUzMeta || {},
    alias: _91_46_46_46slug_93HEjAUM4IUzMeta?.alias || [],
    redirect: _91_46_46_46slug_93HEjAUM4IUzMeta?.redirect || undefined,
    component: () => import("/home/ploi/intercomm.cgworks.eu/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93HEjAUM4IUzMeta?.name ?? "slug___el",
    path: _91_46_46_46slug_93HEjAUM4IUzMeta?.path ?? "/el/:slug(.*)*",
    meta: _91_46_46_46slug_93HEjAUM4IUzMeta || {},
    alias: _91_46_46_46slug_93HEjAUM4IUzMeta?.alias || [],
    redirect: _91_46_46_46slug_93HEjAUM4IUzMeta?.redirect || undefined,
    component: () => import("/home/ploi/intercomm.cgworks.eu/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    path: brandsrqgM6vqY6NMeta?.path ?? "/brands",
    children: [
  {
    name: _91product_93cBId7nbsLRMeta?.name ?? "brands-product",
    path: _91product_93cBId7nbsLRMeta?.path ?? ":product()",
    meta: _91product_93cBId7nbsLRMeta || {},
    alias: _91product_93cBId7nbsLRMeta?.alias || [],
    redirect: _91product_93cBId7nbsLRMeta?.redirect || undefined,
    component: () => import("/home/ploi/intercomm.cgworks.eu/pages/brands/[product].vue").then(m => m.default || m)
  },
  {
    name: indexMYczkMpdhWMeta?.name ?? "brands",
    path: indexMYczkMpdhWMeta?.path ?? "",
    meta: indexMYczkMpdhWMeta || {},
    alias: indexMYczkMpdhWMeta?.alias || [],
    redirect: indexMYczkMpdhWMeta?.redirect || undefined,
    component: () => import("/home/ploi/intercomm.cgworks.eu/pages/brands/index.vue").then(m => m.default || m)
  }
],
    name: brandsrqgM6vqY6NMeta?.name ?? undefined,
    meta: brandsrqgM6vqY6NMeta || {},
    alias: brandsrqgM6vqY6NMeta?.alias || [],
    redirect: brandsrqgM6vqY6NMeta?.redirect || undefined,
    component: () => import("/home/ploi/intercomm.cgworks.eu/pages/brands.vue").then(m => m.default || m)
  },
  {
    path: brandsrqgM6vqY6NMeta?.path ?? "/en/brands",
    children: [
  {
    name: _91product_93cBId7nbsLRMeta?.name ?? "brands-product___en",
    path: _91product_93cBId7nbsLRMeta?.path ?? ":product()",
    meta: _91product_93cBId7nbsLRMeta || {},
    alias: _91product_93cBId7nbsLRMeta?.alias || [],
    redirect: _91product_93cBId7nbsLRMeta?.redirect || undefined,
    component: () => import("/home/ploi/intercomm.cgworks.eu/pages/brands/[product].vue").then(m => m.default || m)
  },
  {
    name: indexMYczkMpdhWMeta?.name ?? "brands___en",
    path: indexMYczkMpdhWMeta?.path ?? "",
    meta: indexMYczkMpdhWMeta || {},
    alias: indexMYczkMpdhWMeta?.alias || [],
    redirect: indexMYczkMpdhWMeta?.redirect || undefined,
    component: () => import("/home/ploi/intercomm.cgworks.eu/pages/brands/index.vue").then(m => m.default || m)
  }
],
    name: brandsrqgM6vqY6NMeta?.name ?? undefined,
    meta: brandsrqgM6vqY6NMeta || {},
    alias: brandsrqgM6vqY6NMeta?.alias || [],
    redirect: brandsrqgM6vqY6NMeta?.redirect || undefined,
    component: () => import("/home/ploi/intercomm.cgworks.eu/pages/brands.vue").then(m => m.default || m)
  },
  {
    path: brandsrqgM6vqY6NMeta?.path ?? "/el/brands",
    children: [
  {
    name: _91product_93cBId7nbsLRMeta?.name ?? "brands-product___el",
    path: _91product_93cBId7nbsLRMeta?.path ?? ":product()",
    meta: _91product_93cBId7nbsLRMeta || {},
    alias: _91product_93cBId7nbsLRMeta?.alias || [],
    redirect: _91product_93cBId7nbsLRMeta?.redirect || undefined,
    component: () => import("/home/ploi/intercomm.cgworks.eu/pages/brands/[product].vue").then(m => m.default || m)
  },
  {
    name: indexMYczkMpdhWMeta?.name ?? "brands___el",
    path: indexMYczkMpdhWMeta?.path ?? "",
    meta: indexMYczkMpdhWMeta || {},
    alias: indexMYczkMpdhWMeta?.alias || [],
    redirect: indexMYczkMpdhWMeta?.redirect || undefined,
    component: () => import("/home/ploi/intercomm.cgworks.eu/pages/brands/index.vue").then(m => m.default || m)
  }
],
    name: brandsrqgM6vqY6NMeta?.name ?? undefined,
    meta: brandsrqgM6vqY6NMeta || {},
    alias: brandsrqgM6vqY6NMeta?.alias || [],
    redirect: brandsrqgM6vqY6NMeta?.redirect || undefined,
    component: () => import("/home/ploi/intercomm.cgworks.eu/pages/brands.vue").then(m => m.default || m)
  },
  {
    path: companyabWxGtiUlHMeta?.path ?? "/company",
    children: [
  {
    name: indexQ8XasWYV3QMeta?.name ?? "company",
    path: indexQ8XasWYV3QMeta?.path ?? "",
    meta: indexQ8XasWYV3QMeta || {},
    alias: indexQ8XasWYV3QMeta?.alias || [],
    redirect: indexQ8XasWYV3QMeta?.redirect || undefined,
    component: () => import("/home/ploi/intercomm.cgworks.eu/pages/company/index.vue").then(m => m.default || m)
  }
],
    name: companyabWxGtiUlHMeta?.name ?? undefined,
    meta: companyabWxGtiUlHMeta || {},
    alias: companyabWxGtiUlHMeta?.alias || [],
    redirect: companyabWxGtiUlHMeta?.redirect || undefined,
    component: () => import("/home/ploi/intercomm.cgworks.eu/pages/company.vue").then(m => m.default || m)
  },
  {
    path: companyabWxGtiUlHMeta?.path ?? "/en/company",
    children: [
  {
    name: indexQ8XasWYV3QMeta?.name ?? "company___en",
    path: indexQ8XasWYV3QMeta?.path ?? "",
    meta: indexQ8XasWYV3QMeta || {},
    alias: indexQ8XasWYV3QMeta?.alias || [],
    redirect: indexQ8XasWYV3QMeta?.redirect || undefined,
    component: () => import("/home/ploi/intercomm.cgworks.eu/pages/company/index.vue").then(m => m.default || m)
  }
],
    name: companyabWxGtiUlHMeta?.name ?? undefined,
    meta: companyabWxGtiUlHMeta || {},
    alias: companyabWxGtiUlHMeta?.alias || [],
    redirect: companyabWxGtiUlHMeta?.redirect || undefined,
    component: () => import("/home/ploi/intercomm.cgworks.eu/pages/company.vue").then(m => m.default || m)
  },
  {
    path: companyabWxGtiUlHMeta?.path ?? "/el/etairia",
    children: [
  {
    name: indexQ8XasWYV3QMeta?.name ?? "company___el",
    path: indexQ8XasWYV3QMeta?.path ?? "",
    meta: indexQ8XasWYV3QMeta || {},
    alias: indexQ8XasWYV3QMeta?.alias || [],
    redirect: indexQ8XasWYV3QMeta?.redirect || undefined,
    component: () => import("/home/ploi/intercomm.cgworks.eu/pages/company/index.vue").then(m => m.default || m)
  }
],
    name: companyabWxGtiUlHMeta?.name ?? undefined,
    meta: companyabWxGtiUlHMeta || {},
    alias: companyabWxGtiUlHMeta?.alias || [],
    redirect: companyabWxGtiUlHMeta?.redirect || undefined,
    component: () => import("/home/ploi/intercomm.cgworks.eu/pages/company.vue").then(m => m.default || m)
  },
  {
    path: contactJ997rGUc3MMeta?.path ?? "/contact",
    children: [
  {
    name: index8WbKIMH13HMeta?.name ?? "contact",
    path: index8WbKIMH13HMeta?.path ?? "",
    meta: index8WbKIMH13HMeta || {},
    alias: index8WbKIMH13HMeta?.alias || [],
    redirect: index8WbKIMH13HMeta?.redirect || undefined,
    component: () => import("/home/ploi/intercomm.cgworks.eu/pages/contact/index.vue").then(m => m.default || m)
  }
],
    name: contactJ997rGUc3MMeta?.name ?? undefined,
    meta: contactJ997rGUc3MMeta || {},
    alias: contactJ997rGUc3MMeta?.alias || [],
    redirect: contactJ997rGUc3MMeta?.redirect || undefined,
    component: () => import("/home/ploi/intercomm.cgworks.eu/pages/contact.vue").then(m => m.default || m)
  },
  {
    path: contactJ997rGUc3MMeta?.path ?? "/en/contact-us",
    children: [
  {
    name: index8WbKIMH13HMeta?.name ?? "contact___en",
    path: index8WbKIMH13HMeta?.path ?? "",
    meta: index8WbKIMH13HMeta || {},
    alias: index8WbKIMH13HMeta?.alias || [],
    redirect: index8WbKIMH13HMeta?.redirect || undefined,
    component: () => import("/home/ploi/intercomm.cgworks.eu/pages/contact/index.vue").then(m => m.default || m)
  }
],
    name: contactJ997rGUc3MMeta?.name ?? undefined,
    meta: contactJ997rGUc3MMeta || {},
    alias: contactJ997rGUc3MMeta?.alias || [],
    redirect: contactJ997rGUc3MMeta?.redirect || undefined,
    component: () => import("/home/ploi/intercomm.cgworks.eu/pages/contact.vue").then(m => m.default || m)
  },
  {
    path: contactJ997rGUc3MMeta?.path ?? "/el/epikoinonia",
    children: [
  {
    name: index8WbKIMH13HMeta?.name ?? "contact___el",
    path: index8WbKIMH13HMeta?.path ?? "",
    meta: index8WbKIMH13HMeta || {},
    alias: index8WbKIMH13HMeta?.alias || [],
    redirect: index8WbKIMH13HMeta?.redirect || undefined,
    component: () => import("/home/ploi/intercomm.cgworks.eu/pages/contact/index.vue").then(m => m.default || m)
  }
],
    name: contactJ997rGUc3MMeta?.name ?? undefined,
    meta: contactJ997rGUc3MMeta || {},
    alias: contactJ997rGUc3MMeta?.alias || [],
    redirect: contactJ997rGUc3MMeta?.redirect || undefined,
    component: () => import("/home/ploi/intercomm.cgworks.eu/pages/contact.vue").then(m => m.default || m)
  },
  {
    path: for_45businessVeARN2QDEcMeta?.path ?? "/for-business",
    children: [
  {
    name: food_45serviceLDOHzJRW5oMeta?.name ?? "for-business-food-service",
    path: food_45serviceLDOHzJRW5oMeta?.path ?? "food-service",
    meta: food_45serviceLDOHzJRW5oMeta || {},
    alias: food_45serviceLDOHzJRW5oMeta?.alias || [],
    redirect: food_45serviceLDOHzJRW5oMeta?.redirect || undefined,
    component: () => import("/home/ploi/intercomm.cgworks.eu/pages/for-business/food-service.vue").then(m => m.default || m)
  },
  {
    name: indexYBCykBGH15Meta?.name ?? "for-business",
    path: indexYBCykBGH15Meta?.path ?? "",
    meta: indexYBCykBGH15Meta || {},
    alias: indexYBCykBGH15Meta?.alias || [],
    redirect: indexYBCykBGH15Meta?.redirect || undefined,
    component: () => import("/home/ploi/intercomm.cgworks.eu/pages/for-business/index.vue").then(m => m.default || m)
  },
  {
    name: private_45labelVPbWV0EDvCMeta?.name ?? "for-business-private-label",
    path: private_45labelVPbWV0EDvCMeta?.path ?? "private-label",
    meta: private_45labelVPbWV0EDvCMeta || {},
    alias: private_45labelVPbWV0EDvCMeta?.alias || [],
    redirect: private_45labelVPbWV0EDvCMeta?.redirect || undefined,
    component: () => import("/home/ploi/intercomm.cgworks.eu/pages/for-business/private-label.vue").then(m => m.default || m)
  }
],
    name: for_45businessVeARN2QDEcMeta?.name ?? undefined,
    meta: for_45businessVeARN2QDEcMeta || {},
    alias: for_45businessVeARN2QDEcMeta?.alias || [],
    redirect: for_45businessVeARN2QDEcMeta?.redirect || undefined,
    component: () => import("/home/ploi/intercomm.cgworks.eu/pages/for-business.vue").then(m => m.default || m)
  },
  {
    path: for_45businessVeARN2QDEcMeta?.path ?? "/en/for-businesses",
    children: [
  {
    name: food_45serviceLDOHzJRW5oMeta?.name ?? "for-business-food-service___en",
    path: food_45serviceLDOHzJRW5oMeta?.path ?? "food-service",
    meta: food_45serviceLDOHzJRW5oMeta || {},
    alias: food_45serviceLDOHzJRW5oMeta?.alias || [],
    redirect: food_45serviceLDOHzJRW5oMeta?.redirect || undefined,
    component: () => import("/home/ploi/intercomm.cgworks.eu/pages/for-business/food-service.vue").then(m => m.default || m)
  },
  {
    name: indexYBCykBGH15Meta?.name ?? "for-business___en",
    path: indexYBCykBGH15Meta?.path ?? "",
    meta: indexYBCykBGH15Meta || {},
    alias: indexYBCykBGH15Meta?.alias || [],
    redirect: indexYBCykBGH15Meta?.redirect || undefined,
    component: () => import("/home/ploi/intercomm.cgworks.eu/pages/for-business/index.vue").then(m => m.default || m)
  },
  {
    name: private_45labelVPbWV0EDvCMeta?.name ?? "for-business-private-label___en",
    path: private_45labelVPbWV0EDvCMeta?.path ?? "private-label",
    meta: private_45labelVPbWV0EDvCMeta || {},
    alias: private_45labelVPbWV0EDvCMeta?.alias || [],
    redirect: private_45labelVPbWV0EDvCMeta?.redirect || undefined,
    component: () => import("/home/ploi/intercomm.cgworks.eu/pages/for-business/private-label.vue").then(m => m.default || m)
  }
],
    name: for_45businessVeARN2QDEcMeta?.name ?? undefined,
    meta: for_45businessVeARN2QDEcMeta || {},
    alias: for_45businessVeARN2QDEcMeta?.alias || [],
    redirect: for_45businessVeARN2QDEcMeta?.redirect || undefined,
    component: () => import("/home/ploi/intercomm.cgworks.eu/pages/for-business.vue").then(m => m.default || m)
  },
  {
    path: for_45businessVeARN2QDEcMeta?.path ?? "/el/gia-epicheiriseis",
    children: [
  {
    name: food_45serviceLDOHzJRW5oMeta?.name ?? "for-business-food-service___el",
    path: food_45serviceLDOHzJRW5oMeta?.path ?? "food-service",
    meta: food_45serviceLDOHzJRW5oMeta || {},
    alias: food_45serviceLDOHzJRW5oMeta?.alias || [],
    redirect: food_45serviceLDOHzJRW5oMeta?.redirect || undefined,
    component: () => import("/home/ploi/intercomm.cgworks.eu/pages/for-business/food-service.vue").then(m => m.default || m)
  },
  {
    name: indexYBCykBGH15Meta?.name ?? "for-business___el",
    path: indexYBCykBGH15Meta?.path ?? "",
    meta: indexYBCykBGH15Meta || {},
    alias: indexYBCykBGH15Meta?.alias || [],
    redirect: indexYBCykBGH15Meta?.redirect || undefined,
    component: () => import("/home/ploi/intercomm.cgworks.eu/pages/for-business/index.vue").then(m => m.default || m)
  },
  {
    name: private_45labelVPbWV0EDvCMeta?.name ?? "for-business-private-label___el",
    path: private_45labelVPbWV0EDvCMeta?.path ?? "/el/gia-epicheiriseis/idiotiki-etiketa",
    meta: private_45labelVPbWV0EDvCMeta || {},
    alias: private_45labelVPbWV0EDvCMeta?.alias || [],
    redirect: private_45labelVPbWV0EDvCMeta?.redirect || undefined,
    component: () => import("/home/ploi/intercomm.cgworks.eu/pages/for-business/private-label.vue").then(m => m.default || m)
  }
],
    name: for_45businessVeARN2QDEcMeta?.name ?? undefined,
    meta: for_45businessVeARN2QDEcMeta || {},
    alias: for_45businessVeARN2QDEcMeta?.alias || [],
    redirect: for_45businessVeARN2QDEcMeta?.redirect || undefined,
    component: () => import("/home/ploi/intercomm.cgworks.eu/pages/for-business.vue").then(m => m.default || m)
  },
  {
    path: indexZFyk6u92rFMeta?.path ?? "/",
    children: [
  {
    name: indexHaq2o8HcU5Meta?.name ?? "index",
    path: indexHaq2o8HcU5Meta?.path ?? "",
    meta: indexHaq2o8HcU5Meta || {},
    alias: indexHaq2o8HcU5Meta?.alias || [],
    redirect: indexHaq2o8HcU5Meta?.redirect || undefined,
    component: () => import("/home/ploi/intercomm.cgworks.eu/pages/index/index.vue").then(m => m.default || m)
  }
],
    name: indexZFyk6u92rFMeta?.name ?? undefined,
    meta: indexZFyk6u92rFMeta || {},
    alias: indexZFyk6u92rFMeta?.alias || [],
    redirect: indexZFyk6u92rFMeta?.redirect || undefined,
    component: () => import("/home/ploi/intercomm.cgworks.eu/pages/index.vue").then(m => m.default || m)
  },
  {
    path: indexZFyk6u92rFMeta?.path ?? "/en",
    children: [
  {
    name: indexHaq2o8HcU5Meta?.name ?? "index___en",
    path: indexHaq2o8HcU5Meta?.path ?? "",
    meta: indexHaq2o8HcU5Meta || {},
    alias: indexHaq2o8HcU5Meta?.alias || [],
    redirect: indexHaq2o8HcU5Meta?.redirect || undefined,
    component: () => import("/home/ploi/intercomm.cgworks.eu/pages/index/index.vue").then(m => m.default || m)
  }
],
    name: indexZFyk6u92rFMeta?.name ?? undefined,
    meta: indexZFyk6u92rFMeta || {},
    alias: indexZFyk6u92rFMeta?.alias || [],
    redirect: indexZFyk6u92rFMeta?.redirect || undefined,
    component: () => import("/home/ploi/intercomm.cgworks.eu/pages/index.vue").then(m => m.default || m)
  },
  {
    path: indexZFyk6u92rFMeta?.path ?? "/el",
    children: [
  {
    name: indexHaq2o8HcU5Meta?.name ?? "index___el",
    path: indexHaq2o8HcU5Meta?.path ?? "",
    meta: indexHaq2o8HcU5Meta || {},
    alias: indexHaq2o8HcU5Meta?.alias || [],
    redirect: indexHaq2o8HcU5Meta?.redirect || undefined,
    component: () => import("/home/ploi/intercomm.cgworks.eu/pages/index/index.vue").then(m => m.default || m)
  }
],
    name: indexZFyk6u92rFMeta?.name ?? undefined,
    meta: indexZFyk6u92rFMeta || {},
    alias: indexZFyk6u92rFMeta?.alias || [],
    redirect: indexZFyk6u92rFMeta?.redirect || undefined,
    component: () => import("/home/ploi/intercomm.cgworks.eu/pages/index.vue").then(m => m.default || m)
  },
  {
    path: newsAacebisTRYMeta?.path ?? "/news",
    children: [
  {
    name: _91newsId_93tUqESXcOGKMeta?.name ?? "news-newsId",
    path: _91newsId_93tUqESXcOGKMeta?.path ?? ":newsId()",
    meta: _91newsId_93tUqESXcOGKMeta || {},
    alias: _91newsId_93tUqESXcOGKMeta?.alias || [],
    redirect: _91newsId_93tUqESXcOGKMeta?.redirect || undefined,
    component: () => import("/home/ploi/intercomm.cgworks.eu/pages/news/[newsId].vue").then(m => m.default || m)
  },
  {
    name: indexQNyMzmYNkhMeta?.name ?? "news",
    path: indexQNyMzmYNkhMeta?.path ?? "",
    meta: indexQNyMzmYNkhMeta || {},
    alias: indexQNyMzmYNkhMeta?.alias || [],
    redirect: indexQNyMzmYNkhMeta?.redirect || undefined,
    component: () => import("/home/ploi/intercomm.cgworks.eu/pages/news/index.vue").then(m => m.default || m)
  }
],
    name: newsAacebisTRYMeta?.name ?? undefined,
    meta: newsAacebisTRYMeta || {},
    alias: newsAacebisTRYMeta?.alias || [],
    redirect: newsAacebisTRYMeta?.redirect || undefined,
    component: () => import("/home/ploi/intercomm.cgworks.eu/pages/news.vue").then(m => m.default || m)
  },
  {
    path: newsAacebisTRYMeta?.path ?? "/en/news",
    children: [
  {
    name: _91newsId_93tUqESXcOGKMeta?.name ?? "news-newsId___en",
    path: _91newsId_93tUqESXcOGKMeta?.path ?? ":newsId()",
    meta: _91newsId_93tUqESXcOGKMeta || {},
    alias: _91newsId_93tUqESXcOGKMeta?.alias || [],
    redirect: _91newsId_93tUqESXcOGKMeta?.redirect || undefined,
    component: () => import("/home/ploi/intercomm.cgworks.eu/pages/news/[newsId].vue").then(m => m.default || m)
  },
  {
    name: indexQNyMzmYNkhMeta?.name ?? "news___en",
    path: indexQNyMzmYNkhMeta?.path ?? "",
    meta: indexQNyMzmYNkhMeta || {},
    alias: indexQNyMzmYNkhMeta?.alias || [],
    redirect: indexQNyMzmYNkhMeta?.redirect || undefined,
    component: () => import("/home/ploi/intercomm.cgworks.eu/pages/news/index.vue").then(m => m.default || m)
  }
],
    name: newsAacebisTRYMeta?.name ?? undefined,
    meta: newsAacebisTRYMeta || {},
    alias: newsAacebisTRYMeta?.alias || [],
    redirect: newsAacebisTRYMeta?.redirect || undefined,
    component: () => import("/home/ploi/intercomm.cgworks.eu/pages/news.vue").then(m => m.default || m)
  },
  {
    path: newsAacebisTRYMeta?.path ?? "/el/nea",
    children: [
  {
    name: _91newsId_93tUqESXcOGKMeta?.name ?? "news-newsId___el",
    path: _91newsId_93tUqESXcOGKMeta?.path ?? ":newsId()",
    meta: _91newsId_93tUqESXcOGKMeta || {},
    alias: _91newsId_93tUqESXcOGKMeta?.alias || [],
    redirect: _91newsId_93tUqESXcOGKMeta?.redirect || undefined,
    component: () => import("/home/ploi/intercomm.cgworks.eu/pages/news/[newsId].vue").then(m => m.default || m)
  },
  {
    name: indexQNyMzmYNkhMeta?.name ?? "news___el",
    path: indexQNyMzmYNkhMeta?.path ?? "",
    meta: indexQNyMzmYNkhMeta || {},
    alias: indexQNyMzmYNkhMeta?.alias || [],
    redirect: indexQNyMzmYNkhMeta?.redirect || undefined,
    component: () => import("/home/ploi/intercomm.cgworks.eu/pages/news/index.vue").then(m => m.default || m)
  }
],
    name: newsAacebisTRYMeta?.name ?? undefined,
    meta: newsAacebisTRYMeta || {},
    alias: newsAacebisTRYMeta?.alias || [],
    redirect: newsAacebisTRYMeta?.redirect || undefined,
    component: () => import("/home/ploi/intercomm.cgworks.eu/pages/news.vue").then(m => m.default || m)
  },
  {
    path: productss0xPw5u0w0Meta?.path ?? "/products",
    children: [
  {
    name: _91subtype_93fwWFYg0Qu1Meta?.name ?? "products-type-subtype",
    path: _91subtype_93fwWFYg0Qu1Meta?.path ?? ":type()/:subtype()",
    meta: _91subtype_93fwWFYg0Qu1Meta || {},
    alias: _91subtype_93fwWFYg0Qu1Meta?.alias || [],
    redirect: _91subtype_93fwWFYg0Qu1Meta?.redirect || undefined,
    component: () => import("/home/ploi/intercomm.cgworks.eu/pages/products/[type]/[subtype].vue").then(m => m.default || m)
  },
  {
    name: indexrE2QPjLWadMeta?.name ?? "products-type",
    path: indexrE2QPjLWadMeta?.path ?? ":type()",
    meta: indexrE2QPjLWadMeta || {},
    alias: indexrE2QPjLWadMeta?.alias || [],
    redirect: indexrE2QPjLWadMeta?.redirect || undefined,
    component: () => import("/home/ploi/intercomm.cgworks.eu/pages/products/[type]/index.vue").then(m => m.default || m)
  },
  {
    name: indexoDXdLIOxiWMeta?.name ?? "products",
    path: indexoDXdLIOxiWMeta?.path ?? "",
    meta: indexoDXdLIOxiWMeta || {},
    alias: indexoDXdLIOxiWMeta?.alias || [],
    redirect: indexoDXdLIOxiWMeta?.redirect || undefined,
    component: () => import("/home/ploi/intercomm.cgworks.eu/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: packagingvl2mONS5J3Meta?.name ?? "products-packaging",
    path: packagingvl2mONS5J3Meta?.path ?? "packaging",
    meta: packagingvl2mONS5J3Meta || {},
    alias: packagingvl2mONS5J3Meta?.alias || [],
    redirect: packagingvl2mONS5J3Meta?.redirect || undefined,
    component: () => import("/home/ploi/intercomm.cgworks.eu/pages/products/packaging.vue").then(m => m.default || m)
  }
],
    name: productss0xPw5u0w0Meta?.name ?? undefined,
    meta: productss0xPw5u0w0Meta || {},
    alias: productss0xPw5u0w0Meta?.alias || [],
    redirect: productss0xPw5u0w0Meta?.redirect || undefined,
    component: () => import("/home/ploi/intercomm.cgworks.eu/pages/products.vue").then(m => m.default || m)
  },
  {
    path: productss0xPw5u0w0Meta?.path ?? "/en/products",
    children: [
  {
    name: _91subtype_93fwWFYg0Qu1Meta?.name ?? "products-type-subtype___en",
    path: _91subtype_93fwWFYg0Qu1Meta?.path ?? ":type()/:subtype()",
    meta: _91subtype_93fwWFYg0Qu1Meta || {},
    alias: _91subtype_93fwWFYg0Qu1Meta?.alias || [],
    redirect: _91subtype_93fwWFYg0Qu1Meta?.redirect || undefined,
    component: () => import("/home/ploi/intercomm.cgworks.eu/pages/products/[type]/[subtype].vue").then(m => m.default || m)
  },
  {
    name: indexrE2QPjLWadMeta?.name ?? "products-type___en",
    path: indexrE2QPjLWadMeta?.path ?? ":type()",
    meta: indexrE2QPjLWadMeta || {},
    alias: indexrE2QPjLWadMeta?.alias || [],
    redirect: indexrE2QPjLWadMeta?.redirect || undefined,
    component: () => import("/home/ploi/intercomm.cgworks.eu/pages/products/[type]/index.vue").then(m => m.default || m)
  },
  {
    name: indexoDXdLIOxiWMeta?.name ?? "products___en",
    path: indexoDXdLIOxiWMeta?.path ?? "",
    meta: indexoDXdLIOxiWMeta || {},
    alias: indexoDXdLIOxiWMeta?.alias || [],
    redirect: indexoDXdLIOxiWMeta?.redirect || undefined,
    component: () => import("/home/ploi/intercomm.cgworks.eu/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: packagingvl2mONS5J3Meta?.name ?? "products-packaging___en",
    path: packagingvl2mONS5J3Meta?.path ?? "packaging",
    meta: packagingvl2mONS5J3Meta || {},
    alias: packagingvl2mONS5J3Meta?.alias || [],
    redirect: packagingvl2mONS5J3Meta?.redirect || undefined,
    component: () => import("/home/ploi/intercomm.cgworks.eu/pages/products/packaging.vue").then(m => m.default || m)
  }
],
    name: productss0xPw5u0w0Meta?.name ?? undefined,
    meta: productss0xPw5u0w0Meta || {},
    alias: productss0xPw5u0w0Meta?.alias || [],
    redirect: productss0xPw5u0w0Meta?.redirect || undefined,
    component: () => import("/home/ploi/intercomm.cgworks.eu/pages/products.vue").then(m => m.default || m)
  },
  {
    path: productss0xPw5u0w0Meta?.path ?? "/el/proionta",
    children: [
  {
    name: _91subtype_93fwWFYg0Qu1Meta?.name ?? "products-type-subtype___el",
    path: _91subtype_93fwWFYg0Qu1Meta?.path ?? ":type()/:subtype()",
    meta: _91subtype_93fwWFYg0Qu1Meta || {},
    alias: _91subtype_93fwWFYg0Qu1Meta?.alias || [],
    redirect: _91subtype_93fwWFYg0Qu1Meta?.redirect || undefined,
    component: () => import("/home/ploi/intercomm.cgworks.eu/pages/products/[type]/[subtype].vue").then(m => m.default || m)
  },
  {
    name: indexrE2QPjLWadMeta?.name ?? "products-type___el",
    path: indexrE2QPjLWadMeta?.path ?? ":type()",
    meta: indexrE2QPjLWadMeta || {},
    alias: indexrE2QPjLWadMeta?.alias || [],
    redirect: indexrE2QPjLWadMeta?.redirect || undefined,
    component: () => import("/home/ploi/intercomm.cgworks.eu/pages/products/[type]/index.vue").then(m => m.default || m)
  },
  {
    name: indexoDXdLIOxiWMeta?.name ?? "products___el",
    path: indexoDXdLIOxiWMeta?.path ?? "",
    meta: indexoDXdLIOxiWMeta || {},
    alias: indexoDXdLIOxiWMeta?.alias || [],
    redirect: indexoDXdLIOxiWMeta?.redirect || undefined,
    component: () => import("/home/ploi/intercomm.cgworks.eu/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: packagingvl2mONS5J3Meta?.name ?? "products-packaging___el",
    path: packagingvl2mONS5J3Meta?.path ?? "packaging",
    meta: packagingvl2mONS5J3Meta || {},
    alias: packagingvl2mONS5J3Meta?.alias || [],
    redirect: packagingvl2mONS5J3Meta?.redirect || undefined,
    component: () => import("/home/ploi/intercomm.cgworks.eu/pages/products/packaging.vue").then(m => m.default || m)
  }
],
    name: productss0xPw5u0w0Meta?.name ?? undefined,
    meta: productss0xPw5u0w0Meta || {},
    alias: productss0xPw5u0w0Meta?.alias || [],
    redirect: productss0xPw5u0w0Meta?.redirect || undefined,
    component: () => import("/home/ploi/intercomm.cgworks.eu/pages/products.vue").then(m => m.default || m)
  },
  {
    path: searchq5oXB2zwEbMeta?.path ?? "/search",
    children: [
  {
    name: indexsYLB25eS7SMeta?.name ?? "search",
    path: indexsYLB25eS7SMeta?.path ?? "",
    meta: indexsYLB25eS7SMeta || {},
    alias: indexsYLB25eS7SMeta?.alias || [],
    redirect: indexsYLB25eS7SMeta?.redirect || undefined,
    component: () => import("/home/ploi/intercomm.cgworks.eu/pages/search/index.vue").then(m => m.default || m)
  }
],
    name: searchq5oXB2zwEbMeta?.name ?? undefined,
    meta: searchq5oXB2zwEbMeta || {},
    alias: searchq5oXB2zwEbMeta?.alias || [],
    redirect: searchq5oXB2zwEbMeta?.redirect || undefined,
    component: () => import("/home/ploi/intercomm.cgworks.eu/pages/search.vue").then(m => m.default || m)
  },
  {
    path: searchq5oXB2zwEbMeta?.path ?? "/en/search",
    children: [
  {
    name: indexsYLB25eS7SMeta?.name ?? "search___en",
    path: indexsYLB25eS7SMeta?.path ?? "",
    meta: indexsYLB25eS7SMeta || {},
    alias: indexsYLB25eS7SMeta?.alias || [],
    redirect: indexsYLB25eS7SMeta?.redirect || undefined,
    component: () => import("/home/ploi/intercomm.cgworks.eu/pages/search/index.vue").then(m => m.default || m)
  }
],
    name: searchq5oXB2zwEbMeta?.name ?? undefined,
    meta: searchq5oXB2zwEbMeta || {},
    alias: searchq5oXB2zwEbMeta?.alias || [],
    redirect: searchq5oXB2zwEbMeta?.redirect || undefined,
    component: () => import("/home/ploi/intercomm.cgworks.eu/pages/search.vue").then(m => m.default || m)
  },
  {
    path: searchq5oXB2zwEbMeta?.path ?? "/el/search",
    children: [
  {
    name: indexsYLB25eS7SMeta?.name ?? "search___el",
    path: indexsYLB25eS7SMeta?.path ?? "",
    meta: indexsYLB25eS7SMeta || {},
    alias: indexsYLB25eS7SMeta?.alias || [],
    redirect: indexsYLB25eS7SMeta?.redirect || undefined,
    component: () => import("/home/ploi/intercomm.cgworks.eu/pages/search/index.vue").then(m => m.default || m)
  }
],
    name: searchq5oXB2zwEbMeta?.name ?? undefined,
    meta: searchq5oXB2zwEbMeta || {},
    alias: searchq5oXB2zwEbMeta?.alias || [],
    redirect: searchq5oXB2zwEbMeta?.redirect || undefined,
    component: () => import("/home/ploi/intercomm.cgworks.eu/pages/search.vue").then(m => m.default || m)
  },
  {
    name: strapirhHU3hjPTUMeta?.name ?? "strapi",
    path: strapirhHU3hjPTUMeta?.path ?? "/strapi",
    meta: strapirhHU3hjPTUMeta || {},
    alias: strapirhHU3hjPTUMeta?.alias || [],
    redirect: strapirhHU3hjPTUMeta?.redirect || undefined,
    component: () => import("/home/ploi/intercomm.cgworks.eu/pages/strapi.vue").then(m => m.default || m)
  },
  {
    name: strapirhHU3hjPTUMeta?.name ?? "strapi___en",
    path: strapirhHU3hjPTUMeta?.path ?? "/en/strapi",
    meta: strapirhHU3hjPTUMeta || {},
    alias: strapirhHU3hjPTUMeta?.alias || [],
    redirect: strapirhHU3hjPTUMeta?.redirect || undefined,
    component: () => import("/home/ploi/intercomm.cgworks.eu/pages/strapi.vue").then(m => m.default || m)
  },
  {
    name: strapirhHU3hjPTUMeta?.name ?? "strapi___el",
    path: strapirhHU3hjPTUMeta?.path ?? "/el/strapi",
    meta: strapirhHU3hjPTUMeta || {},
    alias: strapirhHU3hjPTUMeta?.alias || [],
    redirect: strapirhHU3hjPTUMeta?.redirect || undefined,
    component: () => import("/home/ploi/intercomm.cgworks.eu/pages/strapi.vue").then(m => m.default || m)
  }
]