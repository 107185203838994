//export website navigation links

export const productLinks = [
  {
    id: 0,
    title: "All Products",
    link: "products",
  },
  {
    id: 1,
    title: "Olives",
    link: "olives",
    children: [
      {
        id: 1,
        title: "Conservolea",
        link: "conservolea",
      },
      {
        id: 2,

        title: "Kalamata",
        link: "kalamata",
      },
      {
        id: 3,

        title: "Chalkidiki",
        link: "chalkidiki",
      },
      {
        id: 4,

        title: "Chalkidiki Oxidised",
        link: "chalkidiki-oxidised",
      },
      {
        id: 5,
        packaging: true,
        title: "Olive Packaging",
        link: "packaging",
      },
    ],
  },

  {
    id: 2,
    title: "Fruits",
    link: "fruits",
    children: [
      {
        id: 6,

        title: "Appricot",
        link: "apricot",
      },
      {
        id: 7,
        title: "Peach",
        link: "peach",
      },
      {
        id: 8,
        packaging: true,
        title: "Fruit Packaging",
        link: "packaging",
      },
    ],
  },

  {
    id: 3,
    title: "Antipasti & Other",
    link: "/antipasti-other",
    children: [
      {
        id: 9,
        title: "Antipasti",
        link: "/antipasti",
      },
      {
        id: 10,
        title: "Other",
        link: "/other",
      },
    ],
  },
];

export const sideDrawerLinks = [
  {
    id: 1,
    title: "brands",
    to: { name: "brands" },
  },
  {
    id: 2,
    title: "for-businesses",
    to: { name: "for-business" },
  },
  {
    id: 3,
    title: "company",
    to: { name: "company" },
  },
  {
    id: 4,
    title: "news-link",
    to: { name: "news" },
  },
  {
    id: 5,
    title: "contact",
    to: { name: "contact" },
  },
  {
    id: 6,
    title: "packaging",
    to: { name: "products-packaging" },
  },
];
