// Replace with the API key from your widget installation page
const USERSNAP_GLOBAL_API_KEY = "4c0897ea-ff9c-46db-abc5-920c0aa3b1b4";

export default () => {
  //@ts-ignore
  window.onUsersnapCXLoad = function (api) {
    // store the Usersnap global api on the window, if case you want to use it in other contexts
    //@ts-ignore
    window.Usersnap = api;
    api.init();
  };
  const script = document.createElement("script");
  //@ts-ignore
  script.defer = 1;
  script.src = `https://widget.usersnap.com/global/load/${USERSNAP_GLOBAL_API_KEY}?onload=onUsersnapCXLoad`;
  document.getElementsByTagName("head")[0].appendChild(script);
};
