<template>
  <HeadlessPopover v-slot="{ open }" class="relative">
    <HeadlessPopoverButton
      class="link group inline-flex items-center bg-transparent px-5 py-3 font-sans text-ng600 hover:text-ng800 focus:outline-none"
      :class="{ '!text-ng800': open, 'router-link-active': hasActiveChild }"
    >
      <span>{{ props.title }}</span>
      <span class="item-center ml-0.5 inline-flex w-[9px] justify-center text-ng70">|</span>
      <DownArrow :rotate="open" icon="ep:arrow-down" size="14" />
      <span class="underlined leading-[1.2]"> </span>
    </HeadlessPopoverButton>
    <DropdownTransition>
      <HeadlessPopoverPanel
        class="absolute z-20 mt-3 w-screen max-w-[220px] transform overflow-hidden bg-white py-4 shadow-dropdown"
        ref="panelsContainer"
        v-slot="{ close }"
      >
        <HeadlessDisclosure>
          <NuxtLink
            :to="localePath({ name: 'products' })"
            @click="close"
            class="flex w-full px-5 py-2 text-left text-ng600 hover:bg-ng20 focus:outline-none focus-visible:ring focus-visible:ring-ng70 focus-visible:ring-opacity-75"
            >{{ $t("all-products") }}</NuxtLink
          >
        </HeadlessDisclosure>

        <HeadlessDisclosure v-for="link in dataRef?.productTypes?.data" :key="JSON.stringify(link)" v-slot="{ open }">
          <HeadlessDisclosureButton
            v-if="link.attributes?.product_type_options?.data?.length"
            class="disclosure-btn flex w-full items-center justify-between px-5 py-2 text-left text-ng600 hover:bg-ng20 focus:outline-none focus-visible:ring focus-visible:ring-ng70 focus-visible:ring-opacity-75"
          >
            {{ link.attributes.name }}
            <DownArrow :rotate="open" icon="ep:arrow-down" size="20" />
          </HeadlessDisclosureButton>
          <HeadlessDisclosurePanel
            v-if="link.attributes?.product_type_options?.data?.length"
            class="panel max-h-0 overflow-hidden opacity-0 transition-all duration-200"
            :class="open && 'max-h-[500px] !overflow-visible opacity-100'"
            static
          >
            <div class="mt-1 flex flex-col px-5 text-ng600">
              <NuxtLink
                :to="localePath({ name: 'products-type', params: { type: link.attributes?.slug as string } })"
                @click="close"
                class="w-full px-5 py-1.5 text-left hover:bg-ng20 focus:outline-none focus-visible:ring focus-visible:ring-ng70 focus-visible:ring-opacity-75"
                >{{ $t("all") }}</NuxtLink
              >
              <NuxtLink
                v-for="child in link.attributes?.product_type_options?.data"
                :to="localePath({ name: 'products-type-subtype', params: { type: link.attributes.slug as string , subtype: child.attributes?.slug as string} })"
                :key="child.attributes?.slug"
                @click="close"
                class="w-full px-5 py-1.5 text-left hover:bg-ng20 focus:outline-none focus-visible:ring focus-visible:ring-ng70 focus-visible:ring-opacity-75"
                >{{ child.attributes?.name }}</NuxtLink
              >
            </div>
          </HeadlessDisclosurePanel>

          <NuxtLink
            v-else
            :to="localePath({ name: 'products-type', params: { type: link?.attributes?.slug as string } })"
            @click="close"
            class="flex w-full px-5 py-2 text-left text-ng600 hover:bg-ng20 focus:outline-none focus-visible:ring focus-visible:ring-ng70 focus-visible:ring-opacity-75"
            >{{ link.attributes?.name }}</NuxtLink
          >
        </HeadlessDisclosure>

        <HeadlessDisclosure>
          <NuxtLink
            :to="localePath({ name: 'products-packaging' })"
            @click="close"
            class="flex w-full px-5 py-2 text-left text-ng600 hover:bg-ng20 focus:outline-none focus-visible:ring focus-visible:ring-ng70 focus-visible:ring-opacity-75"
            >{{ $t("packaging") }}</NuxtLink
          >
        </HeadlessDisclosure>
      </HeadlessPopoverPanel>
    </DropdownTransition>
  </HeadlessPopover>
</template>

<script setup lang="ts">
const props = defineProps<{
  title: string;
}>();

const { locale, finalizePendingLocaleChange } = useI18n();

await finalizePendingLocaleChange();

const { data } = await useAsyncGql({
  operation: "GetProductTypesAndSubtypes",
  variables: { locale: locale.value },
  options: {
    lazy: false,
    watch: [locale],
  },
});

const hasActiveChild = computed(() => {
  //if router path has /products/ then return true
  const router = useRouter();
  const path = router.currentRoute.value.path;

  if (path.includes("/products") || path.includes("/proionta")) {
    return true;
  }

  return false;
});

const dataRef = ref(data.value);

watch(locale, async () => {
  await finalizePendingLocaleChange();
  const data = await useAsyncGql({
    operation: "GetProductTypesAndSubtypes",
    variables: { locale: locale.value },
    options: {
      lazy: false,
      watch: [locale],
    },
  });

  dataRef.value = data.data.value;
});

const localePath = useLocalePath();

const { t: $t } = useI18n();

const panelsContainer = ref<HTMLElement | null>(null);
</script>

<style scoped lang="scss">
.link > .underlined {
  padding-bottom: 2px;
  &::after {
    content: "";
    width: 100%;
    height: 8px;
    position: absolute;
    background: theme("colors.ng10");
    left: 0;
    bottom: -11px;
    z-index: -1;
    transform: scaleX(0);
    transform-origin: right;

    transition: transform 0.22s ease-out;
  }
}

.router-link-exact-active {
  @apply bg-ng30;
}

.link {
  &:hover {
    > .underlined::after {
      transform: scaleX(0);

      // background: theme("colors.ng800");
    }
  }

  &.router-link-active {
    > .underlined::after {
      transform: scaleX(1);
      transform-origin: left;

      // background: theme("colors.link-color-green");
    }
  }
}
</style>
