export const useLocaleChanging = defineStore("globalStore", {
  state: () => ({
    localeChanging: false,
    homeVideoVisible: false,
    pageTransitionEnded: true,
    eventModalOpen: false,
  }),

  actions: {
    switch() {
      this.localeChanging = !this.localeChanging;
    },
    toggleEventModal() {
      this.eventModalOpen = !this.eventModalOpen;
    },
    pageTransitionEnd() {
      return new Promise((resolve) => {
        this.pageTransitionEnded = true;
        resolve(void 0);
      });
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useLocaleChanging, import.meta.hot));
}
