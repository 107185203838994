<template>
  <div
    class="grid w-full grid-cols-1 grid-rows-[1fr_2px] items-start bg-footer py-4 px-5 transition duration-200 ease-out laptop:m-auto laptop:grid-cols-[1fr_minmax(0px,407px)_1fr] laptop:py-0 laptop:px-0 laptop:pt-8 laptop:hover:bg-footer-hover-bg"
  >
    <div class="col-span-1 flex-col laptop:col-start-2">
      <p class="pb-4 font-medium text-ng30 laptop:pb-2 laptop:text-xl">
        {{ $t("newsletter") }}
      </p>
      <p class="hidden max-w-[417px] pb-4 text-ng50 laptop:block leading-[1.3]">
        {{ $t("stay-updated-about-our-newest-products-and-collaboration-opportunities") }}
      </p>
      <div class="mb-9 flex flex-col laptop:flex-row">
        <input
          class="mb-2.5 block h-[42px] items-center justify-center bg-footer-opacity py-[11px] text-center tracking-[0.08em] text-white placeholder-white focus-within:outline-none laptop:mb-0 laptop:inline laptop:w-[287px] laptop:bg-footer-opacity laptop:pl-6 laptop:text-left"
          placeholder="E-mail"
        />
        <button
          class="block h-[42px] items-center justify-center border border-transparent bg-n100 py-2.5 leading-[1] tracking-[0.08em] text-footer shadow-subscribe transition duration-200 ease-out hover:border hover:border hover:border-light hover:bg-transparent hover:text-light laptop:inline laptop:w-[129px]"
        >
          {{ $t("subscribe") }}
        </button>
      </div>
    </div>
    <img
      class="col-span-2 col-start-1 row-span-1 row-start-2 w-full"
      alt="line"
      aria-hidden="true"
      src="../../assets/img/footer-line.png"
    />
  </div>
</template>
<script setup></script>
<style lang="scss" scoped></style>
