export const useSearch = defineStore("useSearch", {
  state: () => ({
    searchInput: "",
    searchVisible: false,
    panelOpen: false,
  }),

  actions: {
    setSearchVisible(visible: boolean) {
      this.searchVisible = visible;
    },
    setPanelOpen(open: boolean) {
      this.panelOpen = open;
    },
    clearInput() {
      this.searchInput = "";
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useSearch, import.meta.hot));
}
