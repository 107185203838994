<template>
  <div class="flex flex-col self-center py-2 pt-[18px] laptop:inline-flex laptop:flex-row laptop:pt-[38px]">
    <p class="inline-block self-center text-[13px] text-ng70">
      ©
      <!--{{ startYear }} <span v-if="currentYear !== startYear"> - {{ currentYear }}</span>-->{{ currentYear }}
      INTERCOMM FOODS
    </p>
    <span class="hidden text-ng70 laptop:inline"> &nbsp; | &nbsp; </span>
    <NuxtLink
      to="https://www.cgworks.com"
      target="_blank"
      rel="noopener noreferrer"
      class="cgworks inline-block self-center text-[13px] text-ng70"
      ><strong>CGWORKS</strong> PRODUCTION</NuxtLink
    >
  </div>
</template>
<script setup lang="ts">
const d = new Date();
let startYear = 2022;
let currentYear = d.getFullYear();
</script>
<style lang="scss" scoped>
.cgworks {
  background-image: linear-gradient(90deg, #eaaf00, #eaaf00, #eaaf00),
    linear-gradient(270deg, rgb(172 179 174 / 1), rgb(172 179 174 / 1));
  background-repeat: no-repeat, repeat;
  background-size: 0%, 100%;
  background-position: 50%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition:
    background-size 0.22s ease-out,
    background-position 0.22s ease-out;
  position: relative;

  // &:after {
  //   content: "";
  //   display: block;
  //   width: 100%;
  //   height: 1px;
  //   background-color: #eaaf00;
  //   margin-top: 1px;
  //   position: absolute;
  //   bottom: 0;
  //   transform: scaleX(0);
  //   transition: transform 0.22s ease-out;
  //   transform-origin: left;
  // }

  &:hover {
    background-size: 100%;

    // &:after {
    //   transform: scaleX(1);
    //   transition-delay: 0.3s;
    // }
  }
}
</style>
