<template>
  <NuxtLink
    :to="localePath({ name: 'brands' })"
    class="h-full items-start justify-start bg-footer px-5 py-4 text-left transition duration-300 ease-out tablet:w-full tablet:pt-8 laptop:flex laptop:flex-col laptop:pb-0 laptop:pt-8 laptop:hover:bg-footer-hover-bg"
  >
    <div class="laptop:self-center">
      <p
        class="text-leading-5 pb-2 text-[15px] font-medium text-ng30 tablet:min-w-[350px] tablet:text-sm laptop:text-xl"
      >
        {{ $t("business-options") }}
      </p>
      <p
        class="text-[13px] leading-[1.3] tracking-[0.08em] text-ng50 tablet:min-w-[350px] tablet:text-xs laptop:max-w-[420px] laptop:text-base"
      >
        {{ $t("we-collaborate-with-more-that-65-companies-worldwide-see-our-product-options") }}
      </p>
      <img draggable="false" alt="arrow" class="pt-4" src="../../assets/img/greenArrow.png" />
    </div>
  </NuxtLink>
</template>
<script setup>
const localePath = useLocalePath();
</script>
<style lang="scss" scoped></style>
