import { useGql } from '#imports'
import { getSdk as defaultGqlSdk } from '#gql/default'
export const GqlSdks = {
  default: defaultGqlSdk,
}
export const GqClientOps = {"default":["BrandedProductSizes","ps","pspasd","BlogPostAttributes","Brand","BrandInfo","BrandWithProducts","ProductBrand","Image","Product","ProductBrandInfo","ProductCategories","ProductCuts","ProductIngredients","ProductBrands","ProductSizes","ProductTypes","ProductVarieties","seo","BrandsPage","BrandsPageProducts","BrandsPageProduct","BrandsProductPage","GetBrands","GetBrandsAndProducts","GetBrandBySlug","GetAllProducts","GetSingleBrandAndProducts","GetProductsByBrandSlug","CompanyPage1","CompanyPage2","contactPage","FoodServicePage","ForBusinessPage","NewProducts","ProductCard","PrivateLabelPage","HomePage","FeaturedProducts","OliveOptions","FruitOptions","GetProductTypesAndSubtypes","NewsPage","BlogPosts","CheckIfBlogPostExists","GetBlogPostBySlug","GetEvents","EventInformation","ProductsProductTypeOptionPage","ProductsProductTypePackagingPage","GetProductBySlug","CheckIfProductExists","GetProductVariations","GetProductTypes","ProcessSteps","ProductType","GetCountOfProductVariations","GetRandomPrePackagedProducts","GetProductTypePageData","GetProductTypeOptionsCuts","CheckIfProductTypeExists","CheckIfProductSubTypeExists","GetProductSubTypeData","psp","ProductSizesBySlug","newsTitle","brandsTitle","productsTitle","contactTitle","forBusinessTitle","PrivateLabelTitle","FoodServiceTitle","homeTitle","companyTitle"]}
export const GqlBlogPosts = (...params) => useGql()('BlogPosts', ...params)
export const GqlBrandedProductSizes = (...params) => useGql()('BrandedProductSizes', ...params)
export const GqlBrandsPage = (...params) => useGql()('BrandsPage', ...params)
export const GqlBrandsPageProducts = (...params) => useGql()('BrandsPageProducts', ...params)
export const GqlBrandsProductPage = (...params) => useGql()('BrandsProductPage', ...params)
export const GqlCheckIfBlogPostExists = (...params) => useGql()('CheckIfBlogPostExists', ...params)
export const GqlCheckIfProductExists = (...params) => useGql()('CheckIfProductExists', ...params)
export const GqlCheckIfProductSubTypeExists = (...params) => useGql()('CheckIfProductSubTypeExists', ...params)
export const GqlCheckIfProductTypeExists = (...params) => useGql()('CheckIfProductTypeExists', ...params)
export const GqlCompanyPage1 = (...params) => useGql()('CompanyPage1', ...params)
export const GqlCompanyPage2 = (...params) => useGql()('CompanyPage2', ...params)
export const GqlFoodServicePage = (...params) => useGql()('FoodServicePage', ...params)
export const GqlFoodServiceTitle = (...params) => useGql()('FoodServiceTitle', ...params)
export const GqlForBusinessPage = (...params) => useGql()('ForBusinessPage', ...params)
export const GqlGetAllProducts = (...params) => useGql()('GetAllProducts', ...params)
export const GqlGetBlogPostBySlug = (...params) => useGql()('GetBlogPostBySlug', ...params)
export const GqlGetBrandBySlug = (...params) => useGql()('GetBrandBySlug', ...params)
export const GqlGetBrands = (...params) => useGql()('GetBrands', ...params)
export const GqlGetBrandsAndProducts = (...params) => useGql()('GetBrandsAndProducts', ...params)
export const GqlGetCountOfProductVariations = (...params) => useGql()('GetCountOfProductVariations', ...params)
export const GqlGetEvents = (...params) => useGql()('GetEvents', ...params)
export const GqlGetProductBySlug = (...params) => useGql()('GetProductBySlug', ...params)
export const GqlGetProductSubTypeData = (...params) => useGql()('GetProductSubTypeData', ...params)
export const GqlGetProductTypeOptionsCuts = (...params) => useGql()('GetProductTypeOptionsCuts', ...params)
export const GqlGetProductTypePageData = (...params) => useGql()('GetProductTypePageData', ...params)
export const GqlGetProductTypes = (...params) => useGql()('GetProductTypes', ...params)
export const GqlGetProductTypesAndSubtypes = (...params) => useGql()('GetProductTypesAndSubtypes', ...params)
export const GqlGetProductVariations = (...params) => useGql()('GetProductVariations', ...params)
export const GqlGetProductsByBrandSlug = (...params) => useGql()('GetProductsByBrandSlug', ...params)
export const GqlGetRandomPrePackagedProducts = (...params) => useGql()('GetRandomPrePackagedProducts', ...params)
export const GqlGetSingleBrandAndProducts = (...params) => useGql()('GetSingleBrandAndProducts', ...params)
export const GqlHomePage = (...params) => useGql()('HomePage', ...params)
export const GqlNewsPage = (...params) => useGql()('NewsPage', ...params)
export const GqlPrivateLabelPage = (...params) => useGql()('PrivateLabelPage', ...params)
export const GqlPrivateLabelTitle = (...params) => useGql()('PrivateLabelTitle', ...params)
export const GqlProductSizes = (...params) => useGql()('ProductSizes', ...params)
export const GqlProductSizesBySlug = (...params) => useGql()('ProductSizesBySlug', ...params)
export const GqlProductsProductTypeOptionPage = (...params) => useGql()('ProductsProductTypeOptionPage', ...params)
export const GqlProductsProductTypePackagingPage = (...params) => useGql()('ProductsProductTypePackagingPage', ...params)
export const GqlBrandsTitle = (...params) => useGql()('brandsTitle', ...params)
export const GqlCompanyTitle = (...params) => useGql()('companyTitle', ...params)
export const GqlContactPage = (...params) => useGql()('contactPage', ...params)
export const GqlContactTitle = (...params) => useGql()('contactTitle', ...params)
export const GqlForBusinessTitle = (...params) => useGql()('forBusinessTitle', ...params)
export const GqlHomeTitle = (...params) => useGql()('homeTitle', ...params)
export const GqlNewsTitle = (...params) => useGql()('newsTitle', ...params)
export const GqlProductsTitle = (...params) => useGql()('productsTitle', ...params)