import revive_payload_client_4sVQNw7RlN from "/home/ploi/intercomm.cgworks.eu/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/ploi/intercomm.cgworks.eu/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/ploi/intercomm.cgworks.eu/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/home/ploi/intercomm.cgworks.eu/node_modules/nuxt/dist/app/plugins/payload.client.js";
import plugin_vue3_A0OWXRrUgq from "/home/ploi/intercomm.cgworks.eu/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/home/ploi/intercomm.cgworks.eu/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/ploi/intercomm.cgworks.eu/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_jBToDIZpFa from "/home/ploi/intercomm.cgworks.eu/node_modules/nuxt-graphql-client/dist/runtime/plugin.mjs";
import composition_sLxaNGmlSL from "/home/ploi/intercomm.cgworks.eu/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import meta_5aLvpOrsZz from "/home/ploi/intercomm.cgworks.eu/node_modules/@nuxtjs/i18n/dist/runtime/plugins/meta.mjs";
import i18n_yfWm7jX06p from "/home/ploi/intercomm.cgworks.eu/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_UciE0i6zes from "/home/ploi/intercomm.cgworks.eu/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plyr_client_AWuhaKXXrg from "/home/ploi/intercomm.cgworks.eu/plugins/plyr.client.ts";
import toast_ysMjUcU7eJ from "/home/ploi/intercomm.cgworks.eu/plugins/toast.ts";
import usersnap_client_js_3eDadlwdcf from "/home/ploi/intercomm.cgworks.eu/plugins/usersnap.client.js.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_jBToDIZpFa,
  composition_sLxaNGmlSL,
  meta_5aLvpOrsZz,
  i18n_yfWm7jX06p,
  chunk_reload_client_UciE0i6zes,
  plyr_client_AWuhaKXXrg,
  toast_ysMjUcU7eJ,
  usersnap_client_js_3eDadlwdcf
]