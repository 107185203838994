<template>
  <NuxtLayout>
    <main class="flex h-screen flex-col items-center justify-center">
      <h1 class="text-6xl font-bold">{{ error.statusCode }}</h1>
      <h2 class="text-2xl font-bold">
        <span v-if="error.statusMessage">{{ error.statusMessage }}</span>
        <span v-else-if="error.message">{{ error.message }}</span>
        <span v-else>An error occurred. Please try again later.</span>
      </h2>
      <div v-html="error.stack" v-if="error.stack" class="my-10" />
      <!--      {{ error }}-->
      <NuxtLink :to="localePath({ name: 'index' })" class="text-blue-500 hover:underline">{{
        $t("go-back-home")
      }}</NuxtLink>
    </main>
  </NuxtLayout>
</template>
<script setup lang="ts">
const props = defineProps(["error"]);

const { t: $t } = useI18n();

const localePath = useLocalePath();

useHead({
  title: `404 - ${$t("not-found")} | ${$t("title")}`,
});
</script>
<style lang="scss" scoped></style>
